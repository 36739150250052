import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('client', {
    url: '/clients',
    templateProvider: $templateCache =>
      Promise.all([
        import(/* webpackChunkName: "client" */ './client-fm.pug'),
        import(/* webpackChunkName: "client" */ './client-fm-secret-key.pug'),
        import(/* webpackChunkName: "client" */ '../base/fm-changepass.pug'),
        import(/* webpackChunkName: "client" */ './client.pug'),
      ]).then(([fmTpl, secretKeyTpl, changePassTpl, tpl]) => {
        $templateCache.put('client/client-fm-secret-key', secretKeyTpl.default);
        $templateCache.put('client/client-fm', fmTpl.default);
        $templateCache.put('client/client-fm-pass', changePassTpl.default);
        return tpl.default;
      }),
    controller: 'ClientCtrl as vm',
    requiredPermission: 'client.view',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "client" */ './client.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      clients: Client => Client.find().$promise,
      profiles: Profile => Profile.find().$promise,
    },
  });
}

export default angular.module('app.client.routing', []).config(routes);
