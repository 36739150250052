import angular from 'angular';

export default angular
  .module('app.routing', [
    require('./components/login/login.routing').name,
    require('./components/user-info/user-info.routing').name,
    require('./components/dashboard/dashboard.routing').name,
    require('./components/customer/customer.routing').name,
    require('./components/user/user.routing').name,
    require('./components/profile/profile.routing').name,
    require('./components/common-option/common-option.routing').name,
    require('./components/location/location.routing').name,
    require('./components/locations-config/locations-config.routing').name,
    require('./components/owner/owner.routing').name,
    require('./components/plan/plan.routing').name,
    require('./components/plan-online/plan-online.routing').name,
    require('./components/account/account.routing').name,
    require('./components/pack/pack.routing').name,
    require('./components/pack-group/pack-group.routing').name,
    require('./components/access-code/access-code.routing').name,
    require('./components/client/client.routing').name,
    require('./components/device/device.routing').name,
    require('./components/device-config/device-config.routing').name,
    require('./components/device-console/device-console.routing').name,
    require('./components/alerts/alerts.routing').name,
    require('./components/alert-realtime/alert-realtime.routing').name,
    require('./components/report/report.routing').name,
    require('./components/billing/billing.routing').name,
    require('./components/income-adjustment/income-adjustment.routing').name,
    require('./components/billing-adjustment/billing-adj.routing').name,
    require('./components/alert-realtime/alert-new.routing').name,
    require('./components/audit-log/audit-log.routing').name,
    require('./components/code-update/code-update.routing').name,
    require('./components/prometheus/pms-report.routing').name,
    require('./components/user-group/user-group.routing').name,
    require('./components/settings/settings.routing').name,
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/404');
    $stateProvider.state('default', {
      url: '/404',
      template: '404',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        AuthService.getCurrentUser().then((currentUser) => {
          if (!currentUser) {
            return $state.go('login');
          }

          const state = currentUser.menu.reduce(function find(found, item) {
            return (
              found ||
              item.state ||
              (Array.isArray(item.children) &&
                item.children.reduce(find, found))
            );
          }, null);

          if (state) return $state.go(state);
        });
      },
    });
  });
