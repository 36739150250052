import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('reportIncome2', {
    url: '/income/:tab?location&from&to&interval',
    params: {
      tab: { value: '', squash: true, dynamic: true },
      location: { type: 'string', dynamic: true },
      from: { type: 'string', dynamic: true },
      to: { type: 'string', dynamic: true },
      interval: { type: 'string', dynamic: true },
    },
    templateProvider: () =>
      import(/* webpackChunkName: "rIA" */ './billing-adj.pug').then(
        (tpl) => tpl.default
      ),
    controller: 'IncomeAdjCtrl as vm',
    requiredPermission: 'report.incomeAdj',
    resolve: {
      loadCtrl: ($ocLazyLoad) =>
        Promise.all([
          import(/* webpackChunkName: "rIA" */ './billing-adj'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/date-range-picker'),
          import('../../extensions/directives/highchart'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      $emitter: () =>
        import('eventemitter3').then(({ default: EE3 }) => new EE3()),
      filterConfig: (locations) => ({ locations }),
      reportConfig: () => ({ hideOnline: true, income2: true }),
      locationGroups: (CommonOption) =>
        CommonOption.find({
          filter: {
            where: { type: 'locationGroup' },
            fields: { key: 1, value: 1, meta: 1 },
          },
        }).$promise,
      locations: (Location) =>
        Location.find({ filter: { fields: { id: 1, name: 1, group: 1 } } })
          .$promise,
      plans: (Plan) =>
        Plan.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
      users: (User) =>
        User.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
      translationText: ($translate) =>
        $translate([
          'REPORT.DATETIME.ALL_LOC',
          'REPORT.DATETIME.LOC_GROUP',
          'REPORT.DATETIME.CHOOSE_STAFF',
          'REPORT.DATETIME.TIME',
          'REPORT.DATETIME.BY_HOUR',
          'REPORT.DATETIME.BY_DAY',
          'REPORT.DATETIME.BY_WEEK',
          'REPORT.DATETIME.BY_MONTH',
          'REPORT.INCOME.INCOME_GROUP',
          'REPORT.INCOME.INCOME',
          'REPORT.INCOME.PLAN',
          'REPORT.INCOME.DETAILS_TABLE.LOCATION',
          'REPORT.INCOME.DETAILS_TABLE.TIME',
          'REPORT.INCOME.PLAN',
          'REPORT.INCOME.DETAILS_TABLE.PACK_NUM',
          'REPORT.INCOME.DETAILS_TABLE.TOTAL_INCOME',
        ]),
    },
  });
}
export default angular.module('app.billing-adj.routing', []).config(routes);
