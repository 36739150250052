import angular from 'angular';

function routes($urlRouterProvider, $stateProvider) {
  'ngInject';

  $stateProvider
    .state('alerts', {
      url: '/alerts',
      requiredPermission: 'alexrts.viewAlert',
      onEnter: ($state, $transition$) => {
        $transition$.abort();
        $state.go('alertsAp', $transition$.params());
      },
    })
    .state('_alerts', {
      url: '/alerts',
      templateUrl: '/alerts',
      controller: 'AlertsDetailCtrl as vm',
      requiredPermission: 'alerts.viewAlert',
      abstract: true,
      resolve: {
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "rAD" */ './alerts-detail'),
            import(/* webpackChunkName: "rAD" */ './alerts-detail.pug'),
            // import('./filter/alerts-filter'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
          ]).then(([module, tpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put('/alerts', tpl.default);
          }),
        $emitter: () =>
          import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        locations: (Location) =>
          Location.find({
            filter: { fields: { id: 1, name: 1, group: 1, contactInfo: 1 } },
          }).$promise,
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1 },
            },
          }).$promise,
        hotspots: (Hotspot) =>
          Hotspot.find({
            filter: { fields: { id: 1, mac: 1, name: 1, locationId: 1 } },
          }).$promise,
        events: (RealtimeEvent) => RealtimeEvent.find().$promise,
        locCount: (RealtimeEvent) =>
          RealtimeEvent.countType({ type: 'LOCATION' }).$promise,
        megateCount: (RealtimeEvent) =>
          RealtimeEvent.countType({ type: 'MEGATE' }).$promise,
        apCount: (RealtimeEvent) =>
          RealtimeEvent.countType({ type: 'ACCESSPOINT' }).$promise,
        translationText: ($translate) =>
          $translate([
            'ALERTS.NO',
            'ALERTS.LOCATION',
            'ALERTS.OVERVIEW',
            'ALERTS.SEVERITY',
            'ALERTS.DEVICE',
            'ALERTS.CREATE_DAY',
            'ALERTS.END_DAY',
          ]),
      },
    })
    .state('apTabs', {
      parent: '_alerts',
      url: '/ap',
      templateProvider: () =>
        import(/* webpackChunkName: "rAA" */ './ap/ap-alerts-tabs.pug').then(
          ({ default: tpl }) => tpl,
        ),
    })
    .state('locTabs', {
      parent: '_alerts',
      url: '/loc',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rAL" */ './location/loc-alerts-tabs.pug'
        ).then(({ default: tpl }) => tpl),
    })
    .state('megateTabs', {
      parent: '_alerts',
      url: '/megate',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rAM" */ './megate/megate-alerts-tabs.pug'
        ).then(({ default: tpl }) => tpl),
    })
    .state('alertsAp', {
      parent: 'apTabs',
      url: '/unfinished',
      params: {
        subcate: null,
      },
      templateProvider: () =>
        import(/* webpackChunkName: "rAA" */ './ap/alerts-ap.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'AlertsApCtrl as vm',
      requiredPermission: 'alerts.viewAlert',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "rAA" */ './ap/alerts-ap'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        apEvents: (RealtimeEvent) =>
          RealtimeEvent.find({
            filter: { where: { eventType: 'ACCESSPOINT' } },
          }).$promise,
      },
    })
    .state('alertsMegate', {
      parent: 'megateTabs',
      url: '/unfinished',
      params: {
        subcate: null,
      },
      templateProvider: () =>
        import(/* webpackChunkName: "rAM" */ './megate/alerts-megate.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'AlertsMegateCtrl as vm',
      requiredPermission: 'alerts.viewAlert',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "rAM" */ './megate/alerts-megate'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        megates: (MeGate) =>
          MeGate.find({
            filter: { fields: { id: 1, mac: 1, name: 1, locationId: 1 } },
          }).$promise,
        megateEvents: (RealtimeEvent) =>
          RealtimeEvent.find({
            filter: { where: { eventType: 'MEGATE' } },
          }).$promise,
      },
    })
    .state('alertsLoc', {
      parent: 'locTabs',
      url: '/unfinished',
      params: {
        subcate: null,
      },
      templateProvider: () =>
        import(/* webpackChunkName: "rAL" */ './location/alerts-loc.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'AlertsLocCtrl as vm',
      requiredPermission: 'alerts.viewAlert',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "rAL" */ './location/alerts-loc'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        locEvents: (RealtimeEvent) =>
          RealtimeEvent.find({
            filter: { where: { eventType: 'LOCATION' } },
          }).$promise,
      },
    })
    .state('alertHistoryAp', {
      parent: 'apTabs',
      url: '/history',
      templateProvider: () =>
        import(/* webpackChunkName: "rHA" */ './ap/history-ap.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'HistoryAPCtrl as vm',
      requiredPermission: 'alerts.viewFinishedEvents',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "rHA" */ './ap/history-ap'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        historyApEvents: (HistoryEvent) =>
          HistoryEvent.find({
            filter: { limit: 10000, where: { eventType: 'ACCESSPOINT' } },
          }).$promise,
      },
    })
    .state('alertHistoryMegate', {
      parent: 'megateTabs',
      url: '/history',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rHM" */ './megate/history-megate.pug'
        ).then(({ default: tpl }) => tpl),
      controller: 'HistoryMegateCtrl as vm',
      requiredPermission: 'alerts.viewFinishedEvents',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "rHM" */ './megate/history-megate'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        megates: (MeGate) =>
          MeGate.find({
            filter: { fields: { id: 1, mac: 1, name: 1, locationId: 1 } },
          }).$promise,
        historyMegateEvents: (HistoryEvent) =>
          HistoryEvent.find({
            filter: { limit: 10000, where: { eventType: 'MEGATE' } },
          }).$promise,
      },
    })
    .state('alertHistoryLoc', {
      parent: 'locTabs',
      url: '/history',
      templateProvider: () =>
        import(/* webpackChunkName: "rHL" */ './location/history-loc.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'HistoryLocCtrl as vm',
      requiredPermission: 'alerts.viewFinishedEvents',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "rHL" */ './location/history-loc'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        historyLocEvents: (HistoryEvent) =>
          HistoryEvent.find({
            filter: { limit: 10000, where: { eventType: 'LOCATION' } },
          }).$promise,
      },
    });
}

export default angular.module('app.alert.routing', []).config(routes);
