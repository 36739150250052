import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('profile', {
    url: '/profiles',
    templateProvider: $templateCache =>
      Promise.all([
        import(/* webpackChunkName: "profile" */ './profile.pug'),
        import(/* webpackChunkName: "profile" */ './profile-fm.pug'),
        import(/* webpackChunkName: "profile" */ './menu-tree.pug'),
        import(/* webpackChunkName: "profile" */ './menu-tree-node.pug'),
      ]).then(([tpl, fmTpl, treeTpl, treeNodeTpl]) => {
        $templateCache.put('profile/profile-fm', fmTpl.default);
        $templateCache.put('profile/menu-tree', treeTpl.default);
        $templateCache.put('profile/menu-tree-node', treeNodeTpl.default);
        return tpl.default;
      }),
    controller: 'ProfileCtrl as vm',
    requiredPermission: 'profile.view',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "profile" */ './profile.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/modules/ui-tree'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      roles: Role => Role.find().$promise,
      translationText: $translate =>
        $translate([
          'PROFILE.PROFILE_TABLE.NO',
          'PROFILE.PROFILE_TABLE.NAME',
          'PROFILE.PROFILE_TABLE.DESCRIPTION',
          'PROFILE.PROFILE_TABLE.ACTIONS',
        ]),
    },
  });
}

export default angular.module('app.profile.routing', []).config(routes);
