import angular from 'angular';
import 'angular-translate';

import eng from './lang/en_us.json';
import vie from './lang/en_vi.json';

function configI18n(ConfigProvider, $translateProvider) {
  'ngInject';

  const storageKey = 'lang';

  $translateProvider.storageKey(storageKey);
  $translateProvider.translations('en', eng);
  $translateProvider.translations('vi', vie);
  $translateProvider.useLocalStorage();
  $translateProvider.useSanitizeValueStrategy('escapeParameters');

  const config = ConfigProvider.$get();
  const lang =
    localStorage.getItem(storageKey) || config.get('defaultLanguage') || 'en';
  $translateProvider.use(lang);
}

export default angular
  .module('app.config.i18n', ['pascalprecht.translate'])
  .factory('$translateLocalStorage', () => ({
    put(name, value) {
      localStorage.setItem(name, value);
    },
    get(name) {
      localStorage.getItem(name);
    },
  }))
  .config(configI18n);
