import angular from 'angular';

class MinValidator {
  constructor($timeout, $parse) {
    'ngInject';

    this.$timeout = $timeout;
    this.$parse = $parse;

    this.restrict = 'A';
    this.require = 'ngModel';
  }

  link(scope, el, attrs, ngModel) {
    const minExp = attrs.ngMin;

    const validate = (value) => {
      if (ngModel.$isEmpty(value)) {
        return true;
      }

      const min = isNaN(minExp) ? this.$parse(minExp)(scope) : minExp;
      if (isNaN(min)) return false;
      return +value >= +min;
    };
    ngModel.$validators.min = validate;

    if (isNaN(minExp)) {
      scope.$watch(minExp, () => {
        ngModel.$setValidity('min', validate(ngModel.$modelValue));
      });
    }
  }
}

export default angular
  .module('app.directive.ngMin', [])
  .directive('ngMin', MinValidator);
