import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('settings', {
    url: '/settings',
    templateProvider: () =>
      Promise.all([
        import(/* webpackChunkName: "settings" */ './settings.pug'),
      ]).then(([tpl]) => tpl.default),
    controller: 'SettingsCtrl as vm',
    requiredPermission: 'settings.view',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "client" */ './settings.controller'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
    },
  });
}

export default angular.module('app.client.routing', []).config(routes);
