const NO_SESSION = 'NO_SESSION';
const NO_LOGIN = 'NO_LOGIN';
const NO_USING = 'NO_USING';
const LOW_SESSION = 'LOW_SESSION';
const OFFLINE = 'OFFLINE';
const ONLINE = 'ONLINE';
const OVERLOAD = 'OVERLOAD';
const PPP_DISCONNECTED = 'PPP_DISCONNECTED';
const SERVICE_FAILURE = 'SERVICE_FAILURE';
const LOCATION = 'LOCATION';
const MEGATE = 'MEGATE';
const ACCESSPOINT = 'ACCESSPOINT';
const HIGH = 'HIGH';
const MEDIUM = 'MEDIUM';
const LOW = 'LOW';
const INFO = 'INFO';
const CONFIG_UPDATE_SUCCESS = 'CONFIG_UPDATE_SUCCESS';
const CONFIG_UPDATE_FAILED = 'CONFIG_UPDATE_FAILED';
const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS';
const TEMPLATE_UPDATE_FAILED = 'TEMPLATE_UPDATE_FAILED';
const CODE_UPDATE_SUCCESS = 'CODE_UPDATE_SUCCESS';
const CODE_UPDATE_FAILED = 'CODE_UPDATE_FAILED';

const severities = [INFO, LOW, MEDIUM, HIGH];

const eventSeverity = [
  'LOCATION.NO_SESSION',
  'ACCESSPOINT.OFFLINE',
  'MEGATE.OFFLINE',
  'MEGATE.SERVICE_FAILURE',
  'LOCATION.NO_USING',
  'LOCATION.NO_LOGIN',
  'LOCATION.LOW_SESSION',
  'ACCESSPOINT.OVERLOAD',
  'MEGATE.PPP_DISCONNECTED',
  'ACCESSPOINT.LOW_SESSION',
];

const eventTypes = {
  NO_SESSION,
  LOW_SESSION,
  OFFLINE,
  OVERLOAD,
  PPP_DISCONNECTED,
  SERVICE_FAILURE,
  LOCATION,
  MEGATE,
  ACCESSPOINT,
  ONLINE,
  NO_USING,
  NO_LOGIN,
  HIGH,
  MEDIUM,
  LOW,
  INFO,
  CONFIG_UPDATE_SUCCESS,
  CONFIG_UPDATE_FAILED,
  TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_FAILED,
  CODE_UPDATE_SUCCESS,
  CODE_UPDATE_FAILED,
};

const accessPointEventTypes = [
  OFFLINE,
  OVERLOAD,
  LOW_SESSION,
  CONFIG_UPDATE_SUCCESS,
  CONFIG_UPDATE_FAILED,
  // TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_FAILED,
  CODE_UPDATE_SUCCESS,
  CODE_UPDATE_FAILED,
];

const locationEventTypes = [LOW_SESSION, NO_USING, NO_LOGIN, NO_SESSION];

const eventsWithInfo = [
  {
    eventType: ACCESSPOINT,
    severity: HIGH,
    subEventType: OFFLINE,
    stage: 'alertsAp',
  },
  {
    eventType: MEGATE,
    severity: HIGH,
    subEventType: OFFLINE,
    stage: 'alertsMegate',
  },
  {
    eventType: MEGATE,
    severity: HIGH,
    subEventType: PPP_DISCONNECTED,
    stage: 'alertsMegate',
  },
  {
    eventType: ACCESSPOINT,
    severity: HIGH,
    subEventType: OVERLOAD,
    stage: 'alertsAp',
  },
  {
    eventType: LOCATION,
    severity: HIGH,
    subEventType: NO_SESSION,
    stage: 'alertsLoc',
  },
  {
    eventType: LOCATION,
    severity: MEDIUM,
    subEventType: NO_LOGIN,
    stage: 'alertsLoc',
  },
  {
    eventType: LOCATION,
    severity: MEDIUM,
    subEventType: NO_USING,
    stage: 'alertsLoc',
  },
  {
    eventType: LOCATION,
    severity: MEDIUM,
    subEventType: LOW_SESSION,
    stage: 'alertsLoc',
  },
  {
    eventType: ACCESSPOINT,
    severity: MEDIUM,
    subEventType: LOW_SESSION,
    stage: 'alertsAp',
  },

  {
    eventType: MEGATE,
    severity: HIGH,
    subEventType: SERVICE_FAILURE,
    stage: 'alertsMegate',
  },
];

module.exports = {
  severities,
  eventTypes,
  eventsWithInfo,
  eventSeverity,
  accessPointEventTypes,
  locationEventTypes,
};
