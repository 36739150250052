import angular from 'angular';
import { EventEmitter } from 'eventemitter3';
import { groupBy } from 'lodash';

import { severities } from '../../../../common/constants/alert';

class EventError extends EventEmitter {
  constructor($interval, AuthService, AuthEvents, RealtimeEvent) {
    'ngInject';

    super();

    this.AuthService = AuthService;
    this.RealtimeEvent = RealtimeEvent;

    let _loop;
    const start = () => {
      if (_loop) return;
      this.getData();
      _loop = $interval(this.getData, 30000);
    };
    const stop = () => {
      $interval.cancel(_loop);
      _loop = undefined;
    };

    AuthEvents.on(AuthEvents.loginSuccess, start);
    AuthEvents.on(AuthEvents.logoutSuccess, stop);
    AuthEvents.on(AuthEvents.notAuthenticated, stop);
    if (AuthService.isAuthenticated()) {
      start();
    }
  }

  data = [];
  eventCounter = { total: 0 };

  getData = () => {
    if (!this.AuthService.isAuthenticated()) return [];

    return this.RealtimeEvent.find({
      filter: { order: 'createDay DESC' },
    }).$promise.then((data) => {
      this.data = data;
      this.emit('dataUpdated', data);

      this.eventCounter.total = data.length;
      const eventBySeverity = groupBy(data, 'severity');
      severities.forEach((severity) => {
        this.eventCounter[severity] = (eventBySeverity[severity] || []).length;
      });
    });
  };
}

export default angular
  .module('app.factory.eventError', [])
  .factory('EventError', EventError);
