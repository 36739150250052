import angular from 'angular';

function routes($urlRouterProvider, $stateProvider) {
  'ngInject';

  $stateProvider
    .state('codeVersion', {
      url: '/code/version',
      requiredPermission: 'code.view',
      templateProvider: () =>
        import(/* webpackChunkName: "codev" */ './code/code.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'CodeVersionCtrl as vm',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "codev" */ './code/code.controller'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        translationText: ($translate) =>
          $translate([
            'COMMON.NO',
            'COMMON.ACTIONS',
            'COMMON.DESCRIPTION',
            'COMMON.CREATED_AT',
            'CODE.NAME',
            'CODE.VERSION',
            'CODE.CHECKSUM',
            'CODE.UPLOAD',
          ]),
      },
    })
    .state('codeUpdate', {
      url: '/code/update',
      requiredPermission: 'code.view',
      templateProvider: () =>
        import(/* webpackChunkName: "codeu" */ './update/update.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'CodeUpdateCtrl as vm',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "codeu" */ './update/update.controller'
            ),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        translationText: ($translate) =>
          $translate([
            'COMMON.NO',
            'COMMON.ACTIONS',
            'COMMON.CREATED_AT',
            'HOTSPOT.NAME',
            'HOTSPOT.HOT_FM.MAC',
            'LOCATION.NAME',
            'CODE.CURRENT_VERSION',
            'CODE.SCHEDULED_VERSION',
            'CODE.SCHEDULED_AT',
          ]),
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1 },
            },
          }).$promise,
        cities: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'state' },
              fields: { key: 1, value: 1, meta: 2 },
            },
          }).$promise,
        locations: (Location) =>
          Location.find({
            filter: {
              fields: { id: 1, name: 1, group: 1, city: 1, district: 1 },
            },
          }).$promise,
        codes: (AccessPointCode) => AccessPointCode.find().$promise,
      },
    });
}

export default angular.module('app.code-update.routing', []).config(routes);
