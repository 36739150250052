import angular from 'angular';
import 'bootstrap/js/tooltip';

const BsTooltip = $.fn.tooltip.Constructor;

class Tooltip {
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;

    this.restrict = 'A';
    this.scope = {
      title: '=tooltip',
    };
  }

  link(scope, eles) {
    const [ele] = eles;

    let tooltip;
    const update = () => {
      if (scope.title && tooltip) {
        ele.setAttribute('title', scope.title);
        tooltip.fixTitle();
      } else if (scope.title) {
        tooltip = new BsTooltip(ele, {
          container: document.body,
          title: scope.title,
        });
      } else if (tooltip) {
        tooltip.destroy();
        tooltip = undefined;
      }
    };

    scope.$watch('title', () => {
      update();
    });

    this.$timeout(() => {
      update();
    });

    scope.$on('$destroy', () => {
      if (!tooltip) return;
      tooltip.destroy();
    });
  }
}

export default angular
  .module('app.directive.tooltip', [])
  .directive('tooltip', Tooltip);
