import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('locationsConfig', {
    url: '/locations-config?group',
    params: {
      group: { type: 'string', dynamic: true },
    },
    templateProvider: () =>
      import(/* webpackChunkName: "locsConfig" */ './locations-config.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'LocationsConfigCtrl as vm',
    requiredPermission: 'hotspot.batchConfig',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "locsConfig" */ './locations-config.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/mac-address'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      locationGroups: CommonOption =>
        CommonOption.find({
          filter: {
            where: { type: 'locationGroup' },
            fields: { key: 1, value: 1 },
          },
        }).$promise,
      cities: CommonOption =>
        CommonOption.find({
          filter: {
            where: { type: 'state' },
            fields: { key: 1, value: 1, meta: 2 },
          },
        }).$promise,
      translationText: $translate =>
        $translate([
          'LOCATION.LOC_TABLE.NO',
          'LOCATION.LOC_TABLE.NAME',
          'LOCATION.LOC_TABLE.ADDRESS',
          'LOCATION.LOC_TABLE.DESCRIPTION',
          'LOCATION.LOC_TABLE.GROUP',
          'LOCATION.LOC_TABLE.ACTIONS',
          'REPORT.DATETIME.ALL_LOC',
          'LOCATION.OVER_WRITE',
          'MODEL._LOCATION',
          'REPORT.DATETIME.LOC_GROUP',
        ]),
      locations: Location =>
        Location.find({ filter: { fields: { id: 1, name: 1, group: 1 } } })
          .$promise,
    },
  });
}

export default angular.module('app.locationsConfig.routing', []).config(routes);
