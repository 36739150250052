import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('location', {
    url: '/locations',
    templateProvider: () =>
      import(/* webpackChunkName: "locs" */ './locations.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'LocationListCtrl as vm',
    requiredPermission: 'location.view',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "locs" */ './locations.controller'),
          import('ngmap'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      locationGroups: CommonOption =>
        CommonOption.find({
          filter: {
            where: { type: 'locationGroup' },
            fields: { key: 1, value: 1 },
          },
        }).$promise,
      cities: CommonOption =>
        CommonOption.find({
          filter: {
            where: { type: 'state' },
            fields: { key: 1, value: 1, meta: 2 },
          },
        }).$promise,
      translationText: $translate =>
        $translate([
          'LOCATION.LOC_TABLE.NO',
          'LOCATION.LOC_TABLE.NAME',
          'LOCATION.LOC_TABLE.ADDRESS',
          'LOCATION.LOC_TABLE.DESCRIPTION',
          'LOCATION.LOC_TABLE.GROUP',
          'LOCATION.LOC_TABLE.ACTIONS',
          'REPORT.LOCATION.LOCATION_DETAIL.CONTACT_INFO',
          'REPORT.LOCATION.LOCATION_DETAIL.OPERATOR',
          'REPORT.LOCATION.LOCATION_DETAIL.CONTACT_ONE',
          'REPORT.LOCATION.LOCATION_DETAIL.CONTACT_TWO',
          'REPORT.LOCATION.LOCATION_DETAIL.CTONE_NUMBER',
          'REPORT.LOCATION.LOCATION_DETAIL.CTTWO_NUMBER',
          'REPORT.LOCATION.LOCATION_DETAIL.PHONE_NUMBER',
          'REPORT.LOCATION.LOCATION_DETAIL.EMAIL',
          'REPORT.LOCATION.LOCATION_DETAIL.TECHNICAL_INFO',
          'REPORT.LOCATION.LOCATION_DETAIL.TECH_NUMBER',
          'REPORT.LOCATION.LOCATION_DETAIL.ADDRESS',
          'LOCATION.LOC_FM.CITY',
          'LOCATION.LOC_FM.DISTRICT',
          'LOCATION.LOC_FM.STREET_NAME',
        ]),
    },
  });
}

export default angular.module('app.location.routing', []).config(routes);
