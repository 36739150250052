import angular from 'angular';

function routes($urlRouterProvider, $stateProvider) {
  'ngInject';

  // $urlRouterProvider.otherwise('/pms-report');

  $stateProvider.state('monitor', {
    url: '/monitor?location&from&to&interval&hotspot',
    params: {
      location: { type: 'string', dynamic: true },
      hotspot: { type: 'string', dynamic: true },
      from: { type: 'string', dynamic: true },
      to: { type: 'string', dynamic: true },
      interval: { type: 'string', dynamic: true },
    },
    templateProvider: () =>
      import(/* webpackChunkName: "pms-report" */ './pms-report.pug').then(
        (tpl) => tpl.default,
      ),
    controller: 'PMSReportCtrl as vm',
    // requiredPermission: 'pms-report.view',
    resolve: {
      loadCtrl: ($ocLazyLoad) =>
        Promise.all([
          import(
            /* webpackChunkName: "pms-report" */ './pms-report.controller'
          ),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/highchart/index'),
          import('../../extensions/directives/date-range-picker'),
        ]).then(([module]) => {
          $ocLazyLoad.load({
            name: module.name,
          });
          return module.controller;
        }),
      locations: (Location) =>
        Location.find({
          filter: {
            // where: { status: true },
            fields: { id: 1, name: 1, group: 1, createdAt: 1, status: 1 },
          },
        }).$promise,
      selectedLocation: ($transition$) => $transition$.params().location,
      hotspots: (Hotspot) =>
        Hotspot.find({
          filter: {
            fields: { id: 1, name: 1, mac: 1 },
            where: {
              status: 'onsite',
            },
          },
        }).$promise,
      locationGroups: (CommonOption) =>
        CommonOption.find({
          filter: {
            where: { type: 'locationGroup' },
            fields: { key: 1, value: 1, meta: 1 },
          },
        }).$promise,
      translationText: ($translate) =>
        $translate([
          'DASHBOARD.TODAY_SESSION',
          'DASHBOARD.SESSION_DATA',
          'DASHBOARD.SESSION_TIME',
          'DASHBOARD.LOC_TABLE.DEVICE_RATE',
          'DASHBOARD.AP_GRID.LOGIN',
          'DASHBOARD.AP_GRID.USING',
          'DASHBOARD.AP_GRID.CONNECT',
          'DASHBOARD.LOC_TABLE.GROUP',
          'LOCATION.NAME',
        ]),
    },
  });
}

export default angular.module('pmsReport.routing', []).config(routes);
