import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('login', {
    url: '/login',
    template: require('./login.pug'),
    controller: 'LoginCtrl',
    controllerAs: 'login',
  });
}
export default angular
  .module('app.login.routing', [require('./login.controller').name])
  .config(routes);
