import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('ownerAssignment', {
      url: '/permissions',
      abstract: true,
      resolve: {
        currentUser: (AuthService) => AuthService.getCurrentUser(),
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "owner" */ './owner.controller'),
            import(/* webpackChunkName: "owner" */ './owner.pug'),
            import(/* webpackChunkName: "owner" */ './owner-fm.pug'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module, tpl, fmTpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put('owner', tpl.default);
            $templateCache.put('owner-fm', fmTpl.default);
          }),
        users: (currentUser, User) => {
          const currentUserId = currentUser.id;
          return User.find().$promise.then((users) =>
            users.filter((item) => item.id !== currentUserId),
          );
        },
        translationText: ($translate) =>
          $translate([
            'ASSIGNMENT.ASSIGNMENT_TABLE.NO',
            'ASSIGNMENT.ASSIGNMENT_TABLE.USER',
            'ASSIGNMENT.ASSIGNMENT_TABLE.RESOURCE',
            'ASSIGNMENT.ASSIGNMENT_TABLE.ACTIONS',
            'TEMPLATE.TEMP_TABLE.NO',
            'TEMPLATE.TEMP_TABLE.NAME',
            'TEMPLATE.TEMP_TABLE.DESCRIPTION',
            'TEMPLATE.TEMP_TABLE.LOCATION',
            'TEMPLATE.TEMP_TABLE.ACTIONS',
            'COMMON.REFRESH',
            'REPORT.DATETIME.ALL_LOC',
            'REPORT.DATETIME.LOC_GROUP',
            'REPORT.DATETIME.ALL_HOTSPOT',
            'REPORT.DATETIME.CHOOSE_STAFF',
            'REPORT.DATETIME.TIME',
            'REPORT.DATETIME.BY_HOUR',
            'REPORT.DATETIME.BY_DAY',
            'REPORT.DATETIME.BY_WEEK',
            'REPORT.DATETIME.BY_MONTH',
            'LOCATION.LOC_FM.CITY',
            'LOCATION.LOC_FM.DISTRICT',
          ]),
        cities: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'state' },
              fields: { key: 1, value: 1, meta: 2 },
            },
          }).$promise,
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1, meta: 1 },
            },
          }).$promise,
      },
    })
    .state('locationAssignment', {
      parent: 'ownerAssignment',
      url: '/locations',
      templateUrl: 'owner',
      controller: 'ResourceAssignmentCtrl as vm',
      requiredPermission: 'location.assign',
      resolve: {
        Model: (Location) => Location,
        resources: (Model) =>
          Model.find({ fields: { id: 1, name: 1 } }).$promise,
        resourceType: () => 'Location',
      },
    })
    .state('planAssignment', {
      parent: 'ownerAssignment',
      url: '/plans',
      templateUrl: 'owner',
      controller: 'ResourceAssignmentCtrl as vm',
      requiredPermission: 'plan.assign',
      resolve: {
        Model: (Plan) => Plan,
        resources: (Model) =>
          Model.find({ fields: { id: 1, name: 1 } }).$promise,
        resourceType: () => 'Plan',
      },
    })
    .state('profileAssignment', {
      parent: 'ownerAssignment',
      url: '/profiles',
      templateUrl: 'owner',
      controller: 'ResourceAssignmentCtrl as vm',
      requiredPermission: 'profile.assign',
      resolve: {
        Model: (Profile) => Profile,
        resources: (Model) =>
          Model.find({ fields: { id: 1, name: 1 } }).$promise,
        resourceType: () => 'Profile',
      },
    });
}

export default angular.module('app.assignment.routing', []).config(routes);
