import angular from 'angular';
import { isBefore, isAfter } from 'date-fns';

class ValidatorDate {
  constructor($parse) {
    'ngInject';

    this.$parse = $parse;

    this.restrict = 'A';
    this.require = 'ngModel';
  }

  link(scope, el, attrs, ngModel) {
    const getValidateData = () => {
      const result = {};
      if (attrs.dateBefore) {
        result.dateBefore = this.$parse(attrs.dateBefore)(scope);
      }
      if (attrs.dateAfter) {
        result.dateAfter = this.$parse(attrs.dateAfter)(scope);
      }
      return result;
    };

    const validateBefore = (value = ngModel.$modelValue) => {
      if (ngModel.$isEmpty(value)) {
        return true;
      }

      const { dateBefore } = getValidateData();
      return dateBefore ? isBefore(value, dateBefore) : true;
    };

    const validateAfter = (value = ngModel.$modelValue) => {
      if (ngModel.$isEmpty(value)) {
        return true;
      }

      const { dateAfter } = getValidateData();
      return dateAfter ? isAfter(value, dateAfter) : true;
    };

    if (attrs.dateBefore) {
      scope.$watch(attrs.dateBefore, () => validateBefore());

      ngModel.$validators.dateBefore = validateBefore;
    }
    if (attrs.dateAfter) {
      scope.$watch(attrs.dateAfter, () => validateAfter());

      ngModel.$validators.dateAfter = validateAfter;
    }
  }
}

export default angular
  .module('app.directive.validator-date', [])
  .directive('validatorDate', ValidatorDate);
