import angular from 'angular';

function routes($stateProvider, ConfigProvider) {
  'ngInject';

  const $config = ConfigProvider.$get();

  $stateProvider
    .state('billingReport', {
      url: '/billing-report',
      requiredPermission: 'report.viewBillingReport',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        if (AuthService.isAuthorized('report.income')) {
          $state.go('billingIncome');
        } else if (AuthService.isAuthorized('report.incomeByUser')) {
          $state.go('billingIncomeByUser');
        } else if (AuthService.isAuthorized('report.pack')) {
          $state.go('billingPack');
        } else if (AuthService.isAuthorized('report.packByUser')) {
          $state.go('billingPackByUser');
        }
      },
    })
    .state('_billingReport', {
      url: '/billing-report/:tab?location&user&from&to&interval',
      params: {
        tab: { value: '', squash: true, dynamic: true },
        user: { type: 'string', dynamic: true },
        location: { type: 'string', dynamic: true },
        from: { type: 'string', dynamic: true },
        to: { type: 'string', dynamic: true },
        interval: { type: 'string', dynamic: true },
      },
      templateProvider: () =>
        import(/* webpackChunkName: "rB" */ './billing.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'BillingCtrl as vm',
      requiredPermission: 'report.viewBillingReport',
      abstract: true,
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "rB" */ './billing.controller'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        $emitter: () =>
          import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        filterConfig: (locations, users) => ({ locations, users }),
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1, meta: 1 },
            },
          }).$promise,
        locations: (Location) =>
          Location.find({ filter: { fields: { id: 1, name: 1, group: 1 } } })
            .$promise,
        plans: (Plan) =>
          Plan.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
        users: (User) =>
          User.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
        translationText: ($translate) =>
          $translate([
            'REPORT.DATETIME.ALL_LOC',
            'REPORT.DATETIME.LOC_GROUP',
            'REPORT.DATETIME.CHOOSE_STAFF',
            'REPORT.DATETIME.TIME',
            'REPORT.DATETIME.BY_HOUR',
            'REPORT.DATETIME.BY_DAY',
            'REPORT.DATETIME.BY_WEEK',
            'REPORT.DATETIME.BY_MONTH',
          ]),
      },
    });

  if ($config.get('features.billing.enabled')) {
    $stateProvider
      .state('billingIncome', {
        parent: '_billingReport',
        url: '/income',
        templateProvider: () =>
          import(/* webpackChunkName: "bI" */ './income/income.pug').then(
            (tpl) => tpl.default,
          ),
        controller: 'IncomeCtrl as vm',
        requiredPermission: 'report.income',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(/* webpackChunkName: "bI" */ './income/income'),
              import('../../extensions/init/ag-grid'),
              import('../../extensions/directives/select2'),
              import('../../extensions/directives/date-range-picker'),
              import('../../extensions/directives/highchart'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
              return module.controller;
            }),
          filterConfig: (locations, users) => ({ locations, users }),
          reportConfig: () => ({}),
          translationText: ($translate) =>
            $translate([
              'REPORT.INCOME.INCOME_GROUP',
              'REPORT.INCOME.INCOME',
              'REPORT.INCOME.PLAN',
              'REPORT.INCOME.DETAILS_TABLE.LOCATION',
              'REPORT.INCOME.DETAILS_TABLE.TIME',
              'REPORT.INCOME.PLAN',
              'REPORT.INCOME.DETAILS_TABLE.PACK_NUM',
              'REPORT.INCOME.DETAILS_TABLE.TOTAL_INCOME',
            ]),
        },
      })
      .state('billingIncomeByUser', {
        parent: '_billingReport',
        url: '/income-by-user',
        templateProvider: () =>
          import(/* webpackChunkName: "bIU" */ './income/income.pug').then(
            (tpl) => tpl.default,
          ),
        controller: 'IncomeCtrl as vm',
        requiredPermission: 'report.incomeByUser',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(/* webpackChunkName: "bIU" */ './income/income'),
              // import('./filter/report-filter'),
              import('../../extensions/init/ag-grid'),
              import('../../extensions/directives/select2'),
              import('../../extensions/directives/date-range-picker'),
              import('../../extensions/directives/highchart'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
              return module.controller;
            }),
          filterConfig: (locations) => ({ locations }),
          reportConfig: () => ({ byUser: true, hideOnline: true }),
          translationText: ($translate) =>
            $translate([
              'REPORT.INCOME.INCOME_GROUP',
              'REPORT.INCOME.INCOME',
              'REPORT.INCOME.PLAN',
              'REPORT.INCOME.DETAILS_TABLE.LOCATION',
              'REPORT.INCOME.DETAILS_TABLE.TIME',
              'REPORT.INCOME.PLAN',
              'REPORT.INCOME.DETAILS_TABLE.PACK_NUM',
              'REPORT.INCOME.DETAILS_TABLE.TOTAL_INCOME',
            ]),
        },
      })
      .state('billingPack', {
        parent: '_billingReport',
        url: '/pack',
        templateProvider: () =>
          import(/* webpackChunkName: "bP" */ './pack/report-pack.pug').then(
            (tpl) => tpl.default,
          ),
        controller: 'PackCtrl as vm',
        requiredPermission: 'report.pack',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(/* webpackChunkName: "bP" */ './pack/report-pack'),
              // import('./filter/report-filter'),
              import('../account/account-search-result'),
              import('../../extensions/init/ag-grid'),
              import('../../extensions/directives/select2'),
              import('../../extensions/directives/date-range-picker'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
              return module.controller;
            }),
          reportConfig: () => ({}),
          filterConfig: (locations, users) => ({
            locations,
            users,
            interval: false,
          }),
          translationText: ($translate) =>
            $translate([
              'REPORT.INCOME.REPORT_PACK.NO',
              'REPORT.INCOME.PLAN',
              'REPORT.INCOME.REPORT_PACK.LOCATION',
              'REPORT.INCOME.REPORT_PACK.SELLER',
              'REPORT.INCOME.REPORT_PACK.BUYER',
              'REPORT.INCOME.REPORT_PACK.PRICE',
              'REPORT.INCOME.REPORT_PACK.BUY_DATE',
              'REPORT.INCOME.REPORT_PACK.USE_DATE',
            ]),
        },
      })
      .state('billingPackByUser', {
        parent: '_billingReport',
        url: '/packByUser',
        templateProvider: () =>
          import(/* webpackChunkName: "rbP" */ './pack/report-pack.pug').then(
            (tpl) => tpl.default,
          ),
        controller: 'PackCtrl as vm',
        requiredPermission: 'report.packByUser',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(/* webpackChunkName: "rbP" */ './pack/report-pack'),
              // import('./filter/report-filter'),
              import('../account/account-search-result'),
              import('../../extensions/init/ag-grid'),
              import('../../extensions/directives/select2'),
              import('../../extensions/directives/date-range-picker'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
              return module.controller;
            }),
          reportConfig: () => ({ byUser: true }),
          filterConfig: (locations) => ({
            locations,
            interval: false,
          }),
          translationText: ($translate) =>
            $translate([
              'REPORT.INCOME.REPORT_PACK.NO',
              'REPORT.INCOME.PLAN',
              'REPORT.INCOME.REPORT_PACK.LOCATION',
              'REPORT.INCOME.REPORT_PACK.SELLER',
              'REPORT.INCOME.REPORT_PACK.BUYER',
              'REPORT.INCOME.REPORT_PACK.PRICE',
              'REPORT.INCOME.REPORT_PACK.BUY_DATE',
              'REPORT.INCOME.REPORT_PACK.USE_DATE',
            ]),
        },
      })
      .state('billingActive', {
        url: '/billing-report/active',
        templateProvider: () =>
          import(/* webpackChunkName: "rbA" */ './active/active.pug').then(
            (tpl) => tpl.default,
          ),
        controller: 'ReportActiveCtrl as vm',
        requiredPermission: 'report.active',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(/* webpackChunkName: "rbA" */ './active/active'),
              import('../../extensions/init/ag-grid'),
              import('../../extensions/directives/highchart/index'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
              return module.controller;
            }),
          translationText: ($translate) =>
            $translate([
              'REPORT.ACTIVE.NO',
              'REPORT.ACTIVE.LOCATION',
              'REPORT.ACTIVE.TOTAL_ACCOUNTS',
              'REPORT.ACTIVE.ACTIVE_ACCOUNTS',
              'REPORT.ACTIVE.BLOCK',
              'REPORT.ACTIVE.TOTAL',
              'REPORT.ACTIVE.ACTIVE',
              'REPORT.ACTIVE.INACTIVE',
            ]),
        },
      });
  }
}
export default angular.module('app.billing-report.routing', []).config(routes);
