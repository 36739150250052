import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('access-code', {
    url: '/codes',
    templateProvider: ($templateCache) =>
      Promise.all([
        import(/* webpackChunkName: "plan" */ './access-code-fm.pug'),
        import(/* webpackChunkName: "plan" */ './access-code.pug'),
      ]).then(([fmTpl, tpl]) => {
        $templateCache.put('access-code/access-code-fm', fmTpl.default);
        return tpl.default;
      }),
    controller: 'AccessCodeCtrl as vm',
    requiredPermission: 'accessCode.view',
    resolve: {
      loadCtrl: ($ocLazyLoad) =>
        Promise.all([
          import(/* webpackChunkName: "plan" */ './access-code.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/date-range-picker'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      locations: (Location) =>
        Location.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
      plans: (Plan) =>
        Plan.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
      countCode: () => 0,
      translationText: ($translate) =>
        $translate([
          'LOCATION.NAME',
          'ACCESS_CODE.ACCESS_CODE_TABLE.NO',
          'ACCESS_CODE.ACCESS_CODE_TABLE.DESCRIPTION',
          'ACCESS_CODE.ACCESS_CODE_TABLE.COUNT',
          'ACCESS_CODE.ACCESS_CODE_TABLE.CREATED_AT',
        ]),
    },
  });
}

export default angular.module('app.accessCode.routing', []).config(routes);
