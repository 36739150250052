import angular from 'angular';
import { EventEmitter } from 'eventemitter3';

class AuthEvents extends EventEmitter {
  constructor() {
    'ngInject';

    super();
  }

  loginSuccess = 'auth-login-success';
  loginFailed = 'auth-login-failed';
  logoutSuccess = 'auth-logout-success';
  sessionTimeout = 'auth-session-timeout';
  notAuthenticated = 'auth-not-authenticated';
  notAuthorized = 'auth-not-authorized';
  currentUserUpdated = 'current-user-updated';
}

export default angular
  .module('app.factory.auth-event', [])
  .factory('AuthEvents', AuthEvents);
