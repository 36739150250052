import angular from 'angular';
import './modules/extend';

export default angular.module('app.extensions', [
  require('./constants/messages').name,

  require('./directives/validator').name,
  require('./directives/include-replace').name,
  require('./directives/tooltip').name,
  require('./directives/resize-detector').name,
  require('./directives/copy').name,
  require('./directives/file').name,

  require('./factories/auth-inteceptor').name,
  require('./factories/auth-service').name,
  require('./factories/event-error').name,
  require('./factories/layout').name,
  require('./factories/clipboard').name,

  require('./config/i18n').name,
  require('./config/push-notifs').name
  // require('./config/gmap').name
]);
