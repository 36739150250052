import angular from 'angular';

function resolveLocationId($transition$, $state, $emitter, locations) {
  'ngInject';

  const params = $transition$.params();
  if (params.location) return params.location;

  const [location] = locations;
  if (!location) return;

  $emitter.emit('locationChanged', location.id);
  return location.id;
}

function routes($urlRouterProvider, $stateProvider, ConfigProvider) {
  'ngInject';

  const $config = ConfigProvider.$get();

  $stateProvider
    .state('deviceConfig', {
      url: '/device/config',
      requiredPermission: 'device.viewConfig',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        if (AuthService.isAuthorized('hotspot.viewConfig')) {
          if ($config.get('features.configTemplate.enabled')) {
            $state.go('deviceConfigTemplate');
          } else {
            $state.go('deviceConfigAp');
          }
        } else if (AuthService.isAuthorized('megate.viewConfig')) {
          $state.go('deviceConfigMegate');
        }
      },
    })
    .state('_deviceConfig', {
      url: '/device/config/:type?location',
      abstract: true,
      params: {
        type: {
          type: 'string',
          value: '',
          squash: true,
        },
        location: {
          type: 'string',
          value: '',
          dynamic: true,
        },
      },
      templateProvider: () =>
        import(/* webpackChunkName: "dCnf" */ './device-config.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'DeviceConfigCtrl as vm',
      requiredPermission: 'device.view',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "dCnf" */ './device-config.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/dual-slider'),
          ]).then(([module]) => {
            $ocLazyLoad.load({
              name: module.name,
            });
            return module.controller;
          }),
        $emitter: () =>
          import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        locations: (Location) =>
          Location.find({
            filter: {
              fields: {
                id: 1,
                name: 1,
                status: 1,
              },
            },
          }).$promise,
      },
    })
    .state('deviceConfigRadius', {
      parent: '_deviceConfig',
      url: '/radius',
      templateProvider: () =>
        import(/* webpackChunkName: "dCnfRa" */ './radius/radius.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'RadiusProfileCtrl as vm',
      requiredPermission: 'hotspot.viewConfig',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "dCnfRa" */ './radius/radius.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        locationId: resolveLocationId,
        translationText: ($translate) =>
          $translate([
            'COMMON.NO',
            'COMMON.ACTIONS',
            'RADIUS.NAME',
            'RADIUS.HOST',
            'RADIUS.PORT',
            'RADIUS.ACCT_PORT',
            'RADIUS.SECRET',
            'LIMITATION.MAX_DOWNLOAD',
            'LIMITATION.MAX_UPLOAD',
            'LIMITATION.SESSION_TIMEOUT',
            'LIMITATION.IDLE_TIMEOUT',
          ]),
      },
    });

  if ($config.get('features.configTemplate.enabled')) {
    $stateProvider
      .state('deviceConfigTemplate', {
        parent: '_deviceConfig',
        url: '/template',
        templateProvider: () =>
          import(
            /* webpackChunkName: "dCnfTpl" */ './template/template.pug'
          ).then((tpl) => tpl.default),
        controller: 'ConfigTemplateCtrl as vm',
        requiredPermission: 'hotspot.viewConfig',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(
                /* webpackChunkName: "dCnfTpl" */ './template/template.controller'
              ),
              import('../../extensions/init/ag-grid'),
              import('../../extensions/modules/ui-tree'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
            }),
          translationText: ($translate) =>
            $translate([
              'COMMON.NO',
              'COMMON.ACTIONS',
              'CONF_TPL.NO_TEMPLATE',
              'REPORT.LOCATION.WEEK.MONDAY',
              'REPORT.LOCATION.WEEK.TUESDAY',
              'REPORT.LOCATION.WEEK.WEDNESDAY',
              'REPORT.LOCATION.WEEK.THURSDAY',
              'REPORT.LOCATION.WEEK.FRIDAY',
              'REPORT.LOCATION.WEEK.SATURDAY',
              'REPORT.LOCATION.WEEK.SUNDAY',
              'LOCATION.SSID_CONF.INHERIT',
              'LOCATION.CLONE.CLONE',
              'LOCATION.CLONE.BACKUP',
              'LOCATION.CLONE.RESTORE',
            ]),
          locations: (Location) =>
            Location.find({
              filter: {
                fields: {
                  id: 1,
                  name: 1,
                  city: 1,
                  district: 1,
                  group: 1,
                  hotspotConfig: 1,
                },
              },
            }).$promise,
          loginPages: (LoginPage) =>
            LoginPage.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
          radiusProfiles: (RadiusProfile) =>
            RadiusProfile.find({ filter: { fields: { id: 1, name: 1 } } })
              .$promise,
        },
      })
      .state('deviceConfigTemplateDetail', {
        parent: 'deviceConfigTemplate',
        url: '/:id',
        templateProvider: () =>
          import(
            /* webpackChunkName: "dCnfTplDtl" */ './template/template-detail.pug'
          ).then((tpl) => tpl.default),
        controller: 'ConfigTemplateDetailCtrl as vm',
        requiredPermission: 'hotspot.viewConfig',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(
                /* webpackChunkName: "dCnfTplDtl" */ './template/template-detail'
              ),
              import('../../extensions/init/ag-grid'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
            }),
        },
      })
      .state('deviceConfigApDetail', {
        parent: 'deviceConfigTemplateDetail',
        url: '/ap',
        templateProvider: () =>
          import(
            /* webpackChunkName: "dCnfA" */ './hotspot/hotspot-config-detail.pug'
          ).then((tpl) => tpl.default),
        controller: 'LocationConfigDetailCtrl as vm',
        requiredPermission: 'hotspot.viewConfig',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(
                /* webpackChunkName: "dCnfA" */ './hotspot/hotspot-config-detail'
              ),
              import('../../extensions/init/ag-grid'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
            }),
          locationId: ($transition$) => {
            const params = $transition$.params();
            return params.id;
          },
          translationText: ($translate) =>
            $translate(['LOCATION.SSID_CONF.INHERIT']),
        },
      })
      .state('deviceConfigTemplateAssign', {
        parent: 'deviceConfigTemplate',
        url: '/:id/assign',
        templateProvider: () =>
          import(
            /* webpackChunkName: "dCnfTplAss" */ './template/template-assign.pug'
          ).then((tpl) => tpl.default),
        controller: 'ConfigTemplateAssignCtrl as vm',
        requiredPermission: 'hotspot.viewConfig',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(
                /* webpackChunkName: "dCnfTplAss" */ './template/template-assign'
              ),
              import('../../extensions/init/ag-grid'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
            }),
          translationText: ($translate) =>
            $translate([
              'COMMON.NO',
              'COMMON.ACTIONS',
              'LOCATION.LOC_FM.GROUP',
              'LOCATION.LOC_FM.CITY',
              'LOCATION.LOC_FM.DISTRICT'
            ]),
          locationGroups: CommonOption =>
            CommonOption.find({
              filter: {
                where: { type: 'locationGroup' },
                fields: { key: 1, value: 1, meta: 1 },
              },
            }).$promise,
          cities: CommonOption =>
            CommonOption.find({
              filter: {
                where: { type: 'state' },
                fields: { key: 1, value: 1, meta: 1 },
              },
            }).$promise,
        },
      })
      .state('deviceConfigSchedule', {
        parent: '_deviceConfig',
        url: '/schedule',
        templateProvider: () =>
          import(
            /* webpackChunkName: "dCnfTplSch" */ './schedule/schedule.pug'
          ).then((tpl) => tpl.default),
        controller: 'ConfigScheduleCtrl as vm',
        requiredPermission: 'hotspot.viewConfig',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(
                /* webpackChunkName: "dCnfTplSch" */ './schedule/schedule.controller'
              ),
              import('../../extensions/init/ag-grid'),
              import('../../extensions/directives/select2'),
              import('../../extensions/directives/date-range-picker'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
            }),
          translationText: ($translate) =>
            $translate([
              'COMMON.NO',
              'COMMON.ACTIONS',
              'COMMON.DESCRIPTION',
              'LOCATION.SELECT',
              'LOCATION.NAME',
              'CONF_TPL.NAME',
              'CONF_TPL.NO_TEMPLATE',
              'SCHEDULE.SCHEDULED_AT',
            ]),
          configTemplates: (ConfigTemplate) =>
            ConfigTemplate.find({ filter: { fields: { id: 1, name: 1 } } })
              .$promise,
        },
      });
  } else {
    $stateProvider
      .state('deviceConfigAp', {
        parent: '_deviceConfig',
        url: '/ap',
        templateProvider: () =>
          import(
            /* webpackChunkName: "dCnfA" */ './hotspot/hotspot-config.pug'
          ).then((tpl) => tpl.default),
        controller: 'LocationConfigCtrl as vm',
        requiredPermission: 'hotspot.viewConfig',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(
                /* webpackChunkName: "dCnfA" */ './hotspot/hotspot-config.controller'
              ),
              import(/* webpackChunkName: "dCnfA" */ './device-filter-ap'),
              import('../../extensions/init/ag-grid'),
            ]).then(([module, filter]) => {
              $ocLazyLoad.load({ name: module.name });
              $ocLazyLoad.load({ name: filter.name });
            }),
          locationId: resolveLocationId,
          configBackups: (ConfigBackup) =>
            ConfigBackup.find({
              filter: {
                where: { locationId: resolveLocationId },
              },
            }).$promise,
          translationText: ($translate) =>
            $translate([
              'REPORT.LOCATION.WEEK.MONDAY',
              'REPORT.LOCATION.WEEK.TUESDAY',
              'REPORT.LOCATION.WEEK.WEDNESDAY',
              'REPORT.LOCATION.WEEK.THURSDAY',
              'REPORT.LOCATION.WEEK.FRIDAY',
              'REPORT.LOCATION.WEEK.SATURDAY',
              'REPORT.LOCATION.WEEK.SUNDAY',
              'LOCATION.SSID_CONF.INHERIT',
              'LOCATION.CLONE.BACKUP',
              'LOCATION.CLONE.RESTORE',
            ]),
        },
      })
      .state('deviceConfigApDetail', {
        parent: 'deviceConfigAp',
        url: '/detail',
        templateProvider: () =>
          import(
            /* webpackChunkName: "dCnfA" */ './hotspot/hotspot-config-detail.pug'
          ).then((tpl) => tpl.default),
        controller: 'LocationConfigDetailCtrl as vm',
        requiredPermission: 'hotspot.viewConfig',
        resolve: {
          loadCtrl: ($ocLazyLoad) =>
            Promise.all([
              import(
                /* webpackChunkName: "dCnfA" */ './hotspot/hotspot-config-detail'
              ),
              import('../../extensions/init/ag-grid'),
            ]).then(([module]) => {
              $ocLazyLoad.load({ name: module.name });
            }),
          locationId: resolveLocationId,
          translationText: ($translate) =>
            $translate(['LOCATION.SSID_CONF.INHERIT']),
        },
      });
  }

  if ($config.get('features.manageMeGate.enabled')) {
    $stateProvider.state('deviceConfigMegate', {
      parent: '_deviceConfig',
      url: '/megate',
      views: {
        filter: {
          templateProvider: () =>
            import(
              /* webpackChunkName: "dCnfMe" */ './megate/megate-config-filter.pug'
            ).then((tpl) => tpl.default),
          controller: 'MeGateConfigFilterCtrl as vm',
        },
        '': {
          templateProvider: () =>
            import(
              /* webpackChunkName: "dCnfMe" */ './megate/megate-config.pug'
            ).then((tpl) => tpl.default),
          controller: 'DeviceMegateCtrl as vm',
        },
      },
      requiredPermission: 'megate.viewConfig',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "dCnfMe" */ './megate/megate-config.controller'
            ),
            import(
              /* webpackChunkName: "dCnfMe" */ './megate/megate-config-filter'
            ),
          ]).then(([module, filter]) => {
            $ocLazyLoad.load({ name: module.name });
            $ocLazyLoad.load({ name: filter.name });
          }),
        locationId: resolveLocationId,
      },
    });
  }

  if ($config.get('features.blockStation.enabled')) {
    $stateProvider.state('deviceConfigBlocked', {
      parent: '_deviceConfig',
      url: '/blocked',
      templateProvider: () =>
        import(/* webpackChunkName: "dCnfBl" */ './blocked/blocked.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'BlockedStationCtrl as vm',
      requiredPermission: 'hotspot.viewConfig',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "dCnfBl" */ './blocked/blocked.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        locationId: resolveLocationId,
        translationText: ($translate) =>
          $translate([
            'COMMON.NO',
            'COMMON.ACTIONS',
            'COMMON.CREATED_AT',
            'HOTSPOT.HOT_FM.MAC',
          ]),
      },
    });
  }
}

export default angular.module('app.device-config.routing', []).config(routes);
