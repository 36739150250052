import angular from 'angular';

const ipRegex = new RegExp(
  [
    '^',
    '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.',
    '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.',
    '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.',
    '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)',
    '$',
  ].join(''),
);

const leadingOnes = '(255|254|252|248|240|224|192|128|0+)';
const allOnes = '(255\\.)';
const netmaskRegex = new RegExp(
  [
    '^(',
    `(${allOnes}{3}${leadingOnes})|`,
    `(${allOnes}{2}${leadingOnes}\\.0+)|`,
    `(${allOnes}${leadingOnes}(\\.0+){2})|`,
    `(${leadingOnes}(\\.0+){3})|`,
    ')$',
  ].join(''),
);

const macRegex = /^([A-F0-9][A-F0-9]:){5}[A-F0-9][A-F0-9]$/;

class IpValidator {
  constructor() {
    this.restrict = 'A';
    this.require = 'ngModel';
  }

  link(scope, el, attrs, ngModel) {
    const validate = (value) => {
      if (ngModel.$isEmpty(value)) {
        return true;
      }
      return ipRegex.test(value);
    };
    ngModel.$validators.ip = validate;
  }
}

class NetmaskValidator {
  constructor() {
    this.restrict = 'A';
    this.require = 'ngModel';
  }

  link(scope, el, attrs, ngModel) {
    const validate = (value) => {
      if (ngModel.$isEmpty(value)) {
        return true;
      }
      return netmaskRegex.test(value);
    };
    ngModel.$validators.netmask = validate;
  }
}

class MacAddressValidator {
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;

    this.restrict = 'A';
    this.require = 'ngModel';
  }

  link(scope, el, attrs, ngModel) {
    const validate = (value) => {
      if (ngModel.$isEmpty(value)) {
        return true;
      }
      return macRegex.test(value);
    };
    ngModel.$validators.mac = validate;

    const format = (value) => {
      if (!value) return value;
      const newValue = value
        .replace(/[^a-f0-9]/gi, '')
        .slice(0, 12)
        .match(/[\s\S]{1,2}/g)
        .join(':')
        .toUpperCase();

      return newValue;
    };

    ngModel.$formatters.push(format);
    el.bind('keyup focus', (e) => {
      const value = el.val();
      const newValue = format(value);

      if (newValue !== value) {
        let cursor = e.target.selectionStart;
        if (cursor % 3 === 0) cursor += 1;
        el.val(newValue);
        e.target.selectionStart = cursor;
        e.target.selectionEnd = cursor;

        this.$timeout(() => {
          ngModel.$setValidity('mac', validate(newValue));
        });
      }
    });
  }
}

export default angular
  .module('app.directive.ngNetwork', [])
  .directive('ngIp', IpValidator)
  .directive('ngNetmask', NetmaskValidator)
  .directive('ngMacAddress', MacAddressValidator);
