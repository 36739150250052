import angular from 'angular';
import './validator.less';

const errorMessageMap = {
  'VALIDATE.REQUIRED': ['required', 'parse'],
  'VALIDATE.MIN': 'min',
  'VALIDATE.MAX': 'max',
  'VALIDATE.NUMBER': 'number',
  'VALIDATE.EMAIL': 'email',
  'VALIDATE.URL': 'url',
  'VALIDATE.PATTERN': 'pattern',
  'VALIDATE.MATCHED': 'matched',
  'VALIDATE.DATE_BEFORE': 'dateBefore',
  'VALIDATE.DATE_AFTER': 'dateAfter',
  'VALIDATE.IP': 'ip',
  'VALIDATE.NETMASK': 'netmask',
  'VALIDATE.MAC': 'mac',
};

class ValidatorMessage {
  constructor($translate) {
    'ngInject';

    const errorMessages = {
      default: '{0} is invalid',
    };
    $translate(Object.keys(errorMessageMap)).then((translatedText) => {
      Object.keys(translatedText).forEach((key) => {
        let errors = errorMessageMap[key];
        if (!Array.isArray(errors)) errors = [errors];
        errors.forEach((error) => {
          errorMessages[error] = translatedText[key];
        });
      });
    });

    this.messages = errorMessages;
  }
}

class Validator {
  constructor($parse, validatorMessages) {
    'ngInject';

    this.$parse = $parse;
    this.messages = validatorMessages.messages;

    this.restrict = 'A';
  }

  link(scope, el) {
    let inputEl = el.find('select');
    if (!inputEl.length) inputEl = el.find('input');
    if (!inputEl.length) inputEl = el.find('textarea');
    if (!inputEl.length) {
      throw new Error('Not found any input or select element');
    }

    const ngModel = inputEl.controller('ngModel');
    if (!ngModel) {
      throw new Error('ngModel is required');
    }

    let anchorEl = el.find('.validator-message-holder');
    if (!anchorEl.length) {
      anchorEl = inputEl;
    }

    const messageEl = document.createElement('small');
    if (anchorEl.parent().hasClass('input-group')) {
      anchorEl
        .parent()
        .parent()
        .append(messageEl);
    } else {
      anchorEl.parent().append(messageEl);
    }

    const fieldName = el.attr('data-label') || el.find('label').text();
    let error;

    const updateError = () => {
      el.toggleClass('has-error', ngModel.$invalid);
      if (!ngModel.$pristine) {
        el.toggleClass('has-success', !ngModel.$invalid);
      }

      if (ngModel.$invalid) {
        error = Object.keys(ngModel.$error).pop();
        const message = this.messages[error] || this.messages.default;
        messageEl.innerText = message.format(fieldName);
        messageEl.className = `help-block ${error}`;
      }
    };

    scope.$watch(() => ngModel.$invalid, updateError);
    scope.$watch(() => ngModel.$pristine, updateError);
  }
}

export default angular
  .module('app.directive.validator', [
    require('./validator-matched').name,
    require('./validator-min').name,
    require('./validator-max').name,
    require('./validator-date').name,
    require('./validator-ip').name,
  ])
  .factory('validatorMessages', ValidatorMessage)
  .directive('validator', Validator);
