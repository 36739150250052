import angular from 'angular';

function routes($urlRouterProvider, $stateProvider, ConfigProvider) {
  'ngInject';

  const $config = ConfigProvider.$get();

  $stateProvider
    .state('reports', {
      url: '/reports',
      requiredPermission: 'report.view',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        if (AuthService.isAuthorized('report.info')) {
          $state.go('reportInfo');
        } else if (AuthService.isAuthorized('report.onlineSession')) {
          $state.go('reportOnlineSession');
        } else if (AuthService.isAuthorized('report.apList')) {
          $state.go('reportApList');
        } else if (AuthService.isAuthorized('report.megateList')) {
          $state.go('reportMegateList');
        } else if (AuthService.isAuthorized('report.session')) {
          $state.go('reportSession');
        } else if (AuthService.isAuthorized('report.sessionAvg')) {
          $state.go('reportSessionAvg');
        } else if (AuthService.isAuthorized('report.sessionStat')) {
          $state.go('reportSessionStat');
        }
      },
    })
    .state('_reports', {
      url:
        '/reports/:type?tab&group&city&district&location&from&to&interval&hotspot',
      abstract: true,
      params: {
        type: {
          type: 'string',
          value: '',
          squash: true,
        },
        group: { type: 'string', dynamic: true },
        city: { type: 'string', dynamic: true },
        district: { type: 'string', dynamic: true },
        location: { type: 'string', dynamic: true },
        hotspot: { type: 'string', dynamic: true },
        from: { type: 'string', dynamic: true },
        to: { type: 'string', dynamic: true },
        interval: { type: 'string', dynamic: true },
      },
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './report.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'ReportCtrl as vm',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './report.controller'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
            import('../../extensions/directives/countTo'),
            import('../../extensions/directives/sticky/sticky'),
          ]).then(([module]) => {
            $ocLazyLoad.load({
              name: module.name,
            });
            return module.controller;
          }),
        $emitter: () =>
          import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        currentUser: (AuthService) => AuthService.getCurrentUser(),
        ownerMappings: (Owner) => Owner.find().$promise,
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1, meta: 1 },
            },
          }).$promise,
        locations: (Location) =>
          Location.find({
            filter: {
              fields: {
                id: 1,
                locCode: 1,
                status: 1,
                name: 1,
                group: 1,
                city: 1,
                district: 1,
                description: 1,
                address: 1,
                createdAt: 1,
                contactInfo: 1,
                megate: 1,
              },
            },
          }).$promise,
        hotspots: (Hotspot) =>
          Hotspot.find({
            filter: {
              fields: {
                id: 1,
                mac: 1,
                name: 1,
                model: 1,
                deviceId: 1,
                locationId: 1,
                status: 1,
              },
              where: { status: 'onsite' },
            },
          }).$promise,
        cities: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'state' },
              fields: { key: 1, value: 1, meta: 2 },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'COMMON.REFRESH',
            'REPORT.DATETIME.ALL_LOC',
            'REPORT.DATETIME.LOC_GROUP',
            'REPORT.DATETIME.ALL_HOTSPOT',
            'REPORT.DATETIME.CHOOSE_STAFF',
            'REPORT.DATETIME.TIME',
            'REPORT.DATETIME.BY_HOUR',
            'REPORT.DATETIME.BY_DAY',
            'REPORT.DATETIME.BY_WEEK',
            'REPORT.DATETIME.BY_MONTH',
            'LOCATION.LOC_FM.CITY',
            'LOCATION.LOC_FM.DISTRICT',
          ]),
      },
    })
    .state('reportInfo', {
      parent: '_reports',
      url: '/info',
      templateProvider: () =>
        import(/* webpackChunkName: "rInfo" */ './info/report-info.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'ReportInfoCtrl as vm',
      requiredPermission: 'report.info',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rInfo" */ './info/report-info.controller'
            ),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/highchart'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        translationText: ($translate) => $translate([]),
      },
    })
    .state('reportOnlineSession', {
      parent: '_reports',
      url: '/online-user',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rOnlSs" */
          './online-user/report-online-user.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportOnlineUserCtrl as vm',
      requiredPermission: 'report.onlineSession',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rOnlSs" */
              './online-user/report-online-user.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        translationText: ($translate) =>
          $translate([
            'DASHBOARD.SESSION_GRID.NO',
            'DASHBOARD.SESSION_GRID.USERNAME_MAC',
            'DASHBOARD.SESSION_GRID.AP_NAME',
            'DASHBOARD.SESSION_GRID.SSID',
            'DASHBOARD.SESSION_GRID.DATA',
            'DASHBOARD.SESSION_GRID.IP_ADDR',
            'DASHBOARD.SESSION_GRID.DEVICE',
            'DASHBOARD.SESSION_GRID.START_AT',
          ]),
      },
    })
    .state('reportApList', {
      parent: '_reports',
      url: '/ap-list',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rApLst" */ './ap-list/report-ap-list.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportApListCtrl as vm',
      requiredPermission: 'report.apList',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rApLst" */ './ap-list/report-ap-list.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        translationText: ($translate) =>
          $translate([
            'DASHBOARD.AP_GRID.NO',
            'DASHBOARD.AP_GRID.AP_NAME',
            'DASHBOARD.AP_GRID.IP_ADDR',
            'DASHBOARD.AP_GRID.MAC',
            'DASHBOARD.AP_GRID.UPTIME',
            'DASHBOARD.AP_GRID.CPU',
            'DASHBOARD.AP_GRID.RAM',
            'DASHBOARD.AP_GRID.USING',
            'DASHBOARD.AP_GRID.LOGIN',
            'DASHBOARD.AP_GRID.CONNECT',
            'DASHBOARD.AP_GRID.SSID',
          ]),
      },
    })
    .state('reportBandwidth', {
      parent: '_reports',
      url: '/bandwidth',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rBw" */ './bandwidth/report-bandwidth.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportBandwidthCtrl as vm',
      requiredPermission: 'report.session',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rBw" */ './bandwidth/report-bandwidth'
            ),
            import('../../extensions/directives/highchart'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1, meta: 1 },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'REPORT.BANDWIDTH.TOTAL',
            'REPORT.BANDWIDTH.DOWNLOAD',
            'REPORT.BANDWIDTH.UPLOAD',
            'REPORT.BANDWIDTH.ZOOM',
            'REPORT.BANDWIDTH.ZOOM_TOUCH',
          ]),
      },
    })
    .state('reportSession', {
      parent: '_reports',
      url: '/session',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rSs" */ './session/report-session.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportSessionCtrl as vm',
      requiredPermission: 'report.session',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rSs" */ './session/report-session.controller'
            ),
            import('../../extensions/directives/highchart'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1, meta: 1 },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'REPORT.SESSION.SESSION',
            'REPORT.SESSION.TOTAL',
            'REPORT.SESSION.SESSION_AP_GRID.TOTAL_SESSION',
          ]),
      },
    })
    .state('reportSessionAp', {
      parent: '_reports',
      url: '/session/ap',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rSsAp" */
          './ap-session/report-ap-session.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportApSessionCtrl as vm',
      requiredPermission: 'report.session',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rSsAp" */
              './ap-session/report-ap-session.controller'
            ),
            import('../../extensions/directives/highchart'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        translationText: ($translate) =>
          $translate([
            'REPORT.SESSION.SESSION',
            'REPORT.SESSION.TOTAL',
            'REPORT.SESSION.SESSION_AP_GRID.TOTAL_SESSION',
          ]),
      },
    })
    .state('reportSessionDetail', {
      parent: '_reports',
      url: '/session/detail',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rSsDtl" */
          './session-detail/session-detail.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportSessionDetailCtrl as vm',
      requiredPermission: 'report.session',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rSsDtl" */
              './session-detail/session-detail.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        translationText: ($translate) =>
          $translate([
            'LOCATION.NAME',
            'REPORT.LOCATION.NO',
            'REPORT.LOCATION.LOCATION',
          ]),
      },
    })
    .state('reportSessionDevice', {
      parent: '_reports',
      url: '/session/device',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rSsDv" */ './session-device/session-device.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportDeviceCtrl as vm',
      requiredPermission: 'report.session',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rSsDv" */ './session-device/session-device.controller'
            ),
            import('../../extensions/directives/highchart'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1, meta: 1 },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'REPORT.SESSION.SESSION',
            'REPORT.SESSION.TOTAL',
            'REPORT.SESSION.SESSION_AP_GRID.TOTAL_SESSION',
          ]),
      },
    })
    .state('reportSessionAvg', {
      parent: '_reports',
      url: '/session/avg/:view',
      params: {
        view: { value: 'detail', squash: true, dynamic: true },
      },
      templateProvider: () =>
        import(
          /* webpackChunkName: "rSsAvg" */ './session-avg/session-avg.pug'
        ).then(({ default: tpl }) => tpl),
      controller: 'ReportSessionAvgCtrl as vm',
      requiredPermission: 'report.sessionAvg',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rSsAvg" */ './session-avg/session-avg'
            ),
            import('ngmap'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module, filter]) => {
            $ocLazyLoad.load({ name: module.name });
            $ocLazyLoad.load({ name: filter.name });
          }),
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1, meta: 1 },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'COMMON.CREATED_AT',
            'COMMON.DAYS',
            'COMMON.HOURS',
            'REPORT.LOCATION.NO',
            'REPORT.LOCATION.LOCATION',
            'REPORT.LOCATION.AVG_SESSION',
            'REPORT.LOCATION.LOC_NAME',
            'REPORT.LOCATION.WEEK.WEEKDAYS',
            'REPORT.LOCATION.WEEK.MONDAY',
            'REPORT.LOCATION.WEEK.TUESDAY',
            'REPORT.LOCATION.WEEK.WEDNESDAY',
            'REPORT.LOCATION.WEEK.THURSDAY',
            'REPORT.LOCATION.WEEK.FRIDAY',
            'REPORT.LOCATION.WEEK.SATURDAY',
            'REPORT.LOCATION.WEEK.SUNDAY',
            'REPORT.LOCATION.ACTIONS',
            'REPORT.LOCATION.REACH_RATIO',
            'REPORT.LOCATION.LOCATION_DETAIL.AP_COUNT',
          ]),
      },
    })
    .state('reportSessionStat', {
      parent: '_reports',
      url: '/session/statisic',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rSsStat" */ './statisic/report-statisic.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportStatisicCtrl as vm',
      requiredPermission: 'report.sessionStat',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rSsStat" */ './statisic/report-statisic.controller'
            ),
            import('../../extensions/directives/highchart'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        translationText: ($translate) =>
          $translate([
            'REPORT.LOCATION.LOCATION_DETAIL.CHART.WEEKDAYS',
            'REPORT.LOCATION.WEEK.MONDAY',
            'REPORT.LOCATION.WEEK.TUESDAY',
            'REPORT.LOCATION.WEEK.WEDNESDAY',
            'REPORT.LOCATION.WEEK.THURSDAY',
            'REPORT.LOCATION.WEEK.FRIDAY',
            'REPORT.LOCATION.WEEK.SATURDAY',
            'REPORT.LOCATION.WEEK.SUNDAY',
            'REPORT.LOCATION.LOCATION_DETAIL.CHART.HOURS',
          ]),
      },
    });

  if ($config.get('features.manageMeGate.enabled')) {
    $stateProvider.state('reportMegateList', {
      parent: '_reports',
      url: '/megate',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rMg" */ './megates/report-megates.pug'
        ).then((tpl) => tpl.default),
      controller: 'ReportMegateCtrl as vm',
      requiredPermission: 'report.megateList',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rMg" */ './megates/report-megates.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        translationText: ($translate) =>
          $translate(['MEGATE.NAME', 'MEGATE.SERVICE', 'MEGATE.PPP']),
        meGate: (MeGate) => MeGate.find().$promise,
      },
    });
  }
}

export default angular.module('app.report.routing', []).config(routes);
