import angular from 'angular';

class MaxValidator {
  constructor($timeout, $parse) {
    'ngInject';

    this.$timeout = $timeout;
    this.$parse = $parse;

    this.restrict = 'A';
    this.require = 'ngModel';
  }

  link(scope, el, attrs, ngModel) {
    const maxExp = attrs.ngMax;

    const validate = (value) => {
      if (ngModel.$isEmpty(value)) {
        return true;
      }

      const max = isNaN(maxExp) ? this.$parse(maxExp)(scope) : maxExp;
      if (isNaN(max)) return false;
      return +value <= +max;
    };
    ngModel.$validators.max = validate;

    if (isNaN(maxExp)) {
      scope.$watch(maxExp, () => {
        ngModel.$setValidity('max', validate(ngModel.$modelValue));
      });
    }
  }
}

export default angular
  .module('app.directive.ngMax', [])
  .directive('ngMax', MaxValidator);
