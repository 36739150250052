import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('plan', {
    url: '/plans',
    templateProvider: $templateCache =>
      Promise.all([
        import(/* webpackChunkName: "plan" */ './plan-fm.pug'),
        import(/* webpackChunkName: "plan" */ './plan.pug'),
      ]).then(([fmTpl, tpl]) => {
        $templateCache.put('plan/plan-fm', fmTpl.default);
        return tpl.default;
      }),
    controller: 'PlanCtrl as vm',
    requiredPermission: 'plan.view',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "plan" */ './plan.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/date-range-picker'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      plans: Plan => Plan.find().$promise,
      translationText: $translate =>
        $translate([
          'PLAN.PLAN_TABLE.NO',
          'PLAN.PLAN_TABLE.NAME',
          'PLAN.PLAN_TABLE.PRICE',
          'PLAN.PLAN_TABLE.PLAN_CODE',
          'PLAN.PLAN_TABLE.DESCRIPTION',
          'PLAN.PLAN_TABLE.ACTIONS',
          'PLAN.PLAN_FM.DURATION',
          'PLAN.PLAN_FM.MAX_DATA',
          'PLAN.PLAN_FM.MAX_TIME',
          'PLAN.PLAN_FM.MAX_DEV',
          'PLAN.PLAN_FM.SESSION_TIMEOUT',
          'PLAN.PLAN_FM.BANDWIDTH',
          'PLAN.PLAN_FM.ACCOUNT_CREATION',
          'PLAN.PLAN_FM.MAX_LOGIN',
          'PLAN.PLAN_FM.NO_USER',
        ]),
    },
  });
}

export default angular.module('app.plan.routing', []).config(routes);
