import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('common', {
      abstract: true,
      url: '/common',
      requiredPermission: 'common.view',
    })
    .state('common.locationGroup', {
      url: '/location-group',
      templateProvider: $templateCache =>
        Promise.all([
          import(/* webpackChunkName: "common" */ './common-option.pug'),
          import(/* webpackChunkName: "clg" */ './location-group-fm.pug'),
        ]).then(([tpl, fmTpl]) => {
          $templateCache.put('common/locationGroup', fmTpl.default);
          return tpl.default;
        }),
      controller: 'CommonOptionCtrl as vm',
      requiredPermission: 'common.viewLoc',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "common" */ './common-option.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        translationText: $translate =>
          $translate([
            'COMMON.NO',
            'COMMON.VALUE',
            'COMMON.ACTIONS',
            'LOCATION_GROUP.NAME',
          ]),
        meta: translationText => ({
          commonType: 'locationGroup',
          modelName: translationText['LOCATION_GROUP.NAME'],
          fmSrc: 'common/locationGroup',
        }),
      },
    })
    .state('common.city', {
      url: '/cities',
      templateProvider: $templateCache =>
        Promise.all([
          import(/* webpackChunkName: "common" */ './common-option.pug'),
          import(/* webpackChunkName: "cc" */ './city-fm.pug'),
        ]).then(([tpl, fmTpl]) => {
          $templateCache.put('common/city', fmTpl.default);
          return tpl.default;
        }),
      controller: 'CommonOptionCtrl as vm',
      requiredPermission: 'common.viewCity',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "common" */ './common-option.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        translationText: $translate =>
          $translate([
            'COMMON.NO',
            'COMMON.VALUE',
            'COMMON.ACTIONS',
            'LOCATION_GROUP.NAME',
          ]),
        meta: translationText => ({
          commonType: 'state',
          modelName: translationText['LOCATION_GROUP.NAME'],
          fmSrc: 'common/city',
        }),
      },
    })
    .state('common.ap', {
      url: '/ap',
      templateProvider: $templateCache =>
        Promise.all([
          import(/* webpackChunkName: "common" */ './common-option.pug'),
          import(/* webpackChunkName: "cap" */ './ap-fm.pug'),
        ]).then(([tpl, fmTpl]) => {
          $templateCache.put('common/ap', fmTpl.default);
          return tpl.default;
        }),
      controller: 'CommonOptionCtrl as vm',
      requiredPermission: 'common.viewModel',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "common" */ './common-option.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        translationText: $translate =>
          $translate([
            'COMMON.NO',
            'COMMON.VALUE',
            'COMMON.ACTIONS',
            'LOCATION_GROUP.NAME',
          ]),
        meta: translationText => ({
          commonType: 'apmodel',
          modelName: translationText['LOCATION_GROUP.NAME'],
          fmSrc: 'common/ap',
        }),
      },
    })
    .state('common.megate', {
      url: '/megate',
      templateProvider: $templateCache =>
        Promise.all([
          import(/* webpackChunkName: "common" */ './common-option.pug'),
          import(/* webpackChunkName: "cmegate" */ './megate-fm.pug'),
        ]).then(([tpl, fmTpl]) => {
          $templateCache.put('common/megate', fmTpl.default);
          return tpl.default;
        }),
      controller: 'CommonOptionCtrl as vm',
      requiredPermission: 'common.viewCommonMeGate',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "common" */ './common-option.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        translationText: $translate =>
          $translate([
            'COMMON.NO',
            'COMMON.VALUE',
            'COMMON.ACTIONS',
            'LOCATION_GROUP.NAME',
          ]),
        meta: translationText => ({
          commonType: 'megatemodel',
          modelName: translationText['LOCATION_GROUP.NAME'],
          fmSrc: 'common/megate',
        }),
      },
    })
    .state('common.otherDevice', {
      url: '/other-device',
      templateProvider: $templateCache =>
        Promise.all([
          import(/* webpackChunkName: "common" */ './common-option.pug'),
          import(/* webpackChunkName: "cother" */ './other-device-fm.pug'),
        ]).then(([tpl, fmTpl]) => {
          $templateCache.put('common/other-device', fmTpl.default);
          return tpl.default;
        }),
      controller: 'CommonOptionCtrl as vm',
      requiredPermission: 'common.viewCommonOtherDevice',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "common" */ './common-option.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        translationText: $translate =>
          $translate([
            'COMMON.NO',
            'COMMON.VALUE',
            'COMMON.ACTIONS',
            'LOCATION_GROUP.NAME',
          ]),
        meta: translationText => ({
          commonType: 'othermodel',
          modelName: translationText['LOCATION_GROUP.NAME'],
          fmSrc: 'common/other-device',
        }),
      },
    });
}

export default angular.module('app.common-option.routing', []).config(routes);
