import angular from 'angular';

class Layout {
  constructor($timeout, $window) {
    const onresize = () => {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      // 50 header + 50 footer + 50 padding
      const bodyHeight = height;
      const gridHeight = Math.max(bodyHeight - 68, 400);

      $timeout(() => {
        Object.assign(this, {
          width,
          height,
          bodyHeight,
          gridHeight,
          filterHeight: height / 3,
          reportGroupHeight: height - 330,
          reportAPHeight: height - 430,
        });
      });
    };
    $window.onresize = onresize;
    onresize();
  }
}

export default angular
  .module('app.factory.layout', [])
  .factory('$layout', ($timeout, $window) => {
    'ngInject';

    return new Layout($timeout, $window);
  });
