import angular from 'angular';
import 'angular-desktop-notification';

function configPush(desktopNotificationProvider) {
  'ngInject';

  desktopNotificationProvider.config({
    autoClose: false,
    showOnPageHiddden: true,
  });
}

export default angular.module('app.config.push', ['ngDesktopNotification'])
  .config(configPush);
