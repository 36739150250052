import angular from 'angular';
import { addListener, removeListener } from 'resize-detector';

class ResizeDetector {
  constructor() {
    this.scope = {
      fn: '=resizeDetector',
    };
  }

  link(scope, eles) {
    const [ele] = eles;

    addListener(ele, scope.fn);
    scope.$on('$destroy', () => {
      removeListener(ele, scope.fn);
    });
  }
}

export default angular
  .module('app.directive.resize-detector', [])
  .directive('resizeDetector', ResizeDetector);
