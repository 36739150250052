module.exports = {
  'account.view': ['superadmin', 'viewAccount', 'manageAccount'],
  'account.create': ['superadmin', 'manageAccount', 'createAccount'],
  'account.edit': ['superadmin', 'manageAccount', 'editAccount'],
  'account.delete': ['superadmin', 'manageAccount', 'deleteAccount'],
  'account.changePass': ['superadmin', 'manageAccount', 'editAccount'],

  'alerts.viewAlert': ['superadmin', 'sysadmin', 'viewAlert'],
  'alerts.editAlert': ['superadmin', 'sysadmin', 'editAlert'],
  'alerts.viewFinishedEvents': ['superadmin', 'sysadmin', 'viewAlert'],

  'billing.adjustIncome': ['superadmin', 'manageAdjustment'],

  'customer.view': ['superadmin', 'viewCustomer', 'manageCustomer'],
  'customer.create': ['superadmin', 'manageCustomer'],
  'customer.edit': ['superadmin', 'manageCustomer'],
  'customer.delete': ['superadmin', 'manageCustomer'],

  'accessCode.view': ['superadmin', 'viewAccessCode', 'manageAccessCode'],
  'accessCode.create': ['superadmin', 'manageAccessCode'],
  'accessCode.edit': ['superadmin', 'manageAccessCode'],
  'accessCode.delete': ['superadmin', 'manageAccessCode'],

  'client.view': [
    'superadmin',
    'viewClient',
    'manageClient',
    'viewAll',
    'viewAllClient',
    'viewAllClientOfCustomer',
  ],
  'client.create': ['superadmin', 'manageClient'],
  'client.edit': ['superadmin', 'manageClient'],
  'client.delete': ['superadmin', 'manageClient'],
  'client.secretKey': ['superadmin', 'manageClientSecretKey'],

  'common.viewLoc': ['superadmin', 'manageCommonLoc', 'manageLocation'],
  'common.viewCity': ['superadmin', 'manageCommonCity', 'manageLocation'],
  'common.viewModel': ['superadmin', 'manageCommonModel', 'manageLocation'],
  'common.viewCommonOtherDevice': [
    'superadmin',
    'manageOtherDevice',
    'manageLocation',
  ],
  'common.viewCommonMeGate': [
    'superadmin',
    'manageCommonMeGate',
    'manageLocation',
  ],
  'common.create': [
    'superadmin',
    'manageLocation',
    'manageCommonLoc',
    'manageCommonCity',
    'manageCommonModel',
    'manageCommonOtherDevice',
    'manageCommonMeGate',
  ],
  'common.edit': [
    'superadmin',
    'manageLocation',
    'manageCommonLoc',
    'manageCommonCity',
    'manageCommonModel',
    'manageCommonOtherDevice',
    'manageCommonMeGate',
  ],
  'common.delete': [
    'superadmin',
    'manageLocation',
    'manageCommonLoc',
    'manageCommonCity',
    'manageCommonModel',
    'manageCommonOtherDevice',
    'manageCommonMeGate',
  ],

  'device.view': [
    'superadmin',
    'viewHotspot',
    'manageHotspot',
    'viewMeGate',
    'manageMeGate',
    'viewLoginPage',
    'manageLoginPage',
    'renameLoginPage',
  ],
  'device.config': ['superadmin', 'manageHotspotConfig', 'manageMeGateConfig'],
  'device.viewConfig': [
    'superadmin',
    'viewHotspotConfig',
    'viewMeGateConfig',
    'manageHotspotConfig',
    'manageMeGateConfig',
  ],
  'device.console': [
    'superadmin',
    'interactHotspotConsole',
    'interactMeGateConsole',
  ],

  'user.view': [
    'superadmin',
    'viewUser',
    'manageUser',
    'viewAll',
    'viewAllUser',
    'viewAllUserOfCustomer',
  ],
  'user.create': ['superadmin', 'manageUser'],
  'user.edit': ['superadmin', 'manageUser'],
  'user.delete': ['superadmin', 'manageUser'],
  'user.changeSession': ['superadmin', 'manageUser'],

  'plan.view': [
    'superadmin',
    'viewPlan',
    'managePlan',
    'viewAll',
    'viewAllPlan',
    'viewAllPlanOfCustomer',
  ],
  'plan.create': ['superadmin', 'managePlan'],
  'plan.edit': ['superadmin', 'managePlan'],
  'plan.delete': ['superadmin', 'managePlan'],
  'plan.assign': ['superadmin', 'managePlanOwner'],
  'pack.create': ['superadmin', 'sellPack'],

  'planOnline.view': [
    'superadmin',
    'viewPlanOnline',
    'managePlanOnline',
    'viewAll',
  ],
  'planOnline.create': ['superadmin', 'managePlanOnline'],
  'planOnline.edit': ['superadmin', 'managePlanOnline'],
  'planOnline.delete': ['superadmin', 'managePlanOnline'],

  'profile.view': ['superadmin', 'sysadmin', 'viewProfile', 'manageProfile'],
  'profile.create': ['superadmin', 'sysadmin', 'manageProfile'],
  'profile.edit': ['superadmin', 'sysadmin', 'manageProfile'],
  'profile.delete': ['superadmin', 'sysadmin', 'manageProfile'],
  'profile.assign': ['superadmin', 'sysadmin', 'manageProfileOwner'],

  'userGroup.view': [
    'superadmin',
    'sysadmin',
    'viewUserGroup',
    'manageUserGroup',
  ],
  'userGroup.create': ['superadmin', 'sysadmin', 'manageUserGroup'],
  'userGroup.edit': ['superadmin', 'sysadmin', 'manageUserGroup'],
  'userGroup.delete': ['superadmin', 'sysadmin', 'manageUserGroup'],

  'location.view': [
    'superadmin',
    'viewLocation',
    'manageLocation',
    'viewAll',
    'viewAllLocation',
    'viewAllLocationOfCustomer',
  ],
  'location.create': ['superadmin', 'manageLocation'],
  'location.edit': ['superadmin', 'manageLocation'],
  'location.delete': ['superadmin', 'manageLocation'],
  'location.assign': ['superadmin', 'manageLocationOwner'],
  'location.rename': ['superadmin', 'manageLocation', 'renameLocation'],
  'location.updateGroup': ['superadmin', 'updateLocationGroup'],
  'location.updateGeo': ['superadmin', 'manageLocation', 'updateLocationGeo'],
  'location.viewMap': ['superadmin', 'viewLocationMap'],
  'location.editLocBlueprints': ['superadmin', 'editLocBlueprints'],
  'location.editContact': ['superadmin', 'editContact'],
  'location.disableSite': ['superadmin', 'disableSite'],

  'hotspot.view': ['superadmin', 'sysadmin', 'viewHotspot', 'manageHotspot'],
  'hotspot.create': ['superadmin', 'sysadmin', 'manageHotspot'],
  'hotspot.edit': ['superadmin', 'sysadmin', 'manageHotspot'],
  'hotspot.delete': ['superadmin', 'sysadmin', 'manageHotspot'],
  'hotspot.config': ['superadmin', 'sysadmin', 'manageHotspotConfig'],
  'hotspot.configSchedule': ['superadmin', 'sysadmin', 'manageConfigSchedule'],
  'hotspot.batchConfig': ['superadmin', 'sysadmin', 'manageLocationsConfig'],
  'hotspot.viewConfig': [
    'superadmin',
    'sysadmin',
    'viewHotspotConfig',
    'manageHotspotConfig',
  ],
  'hotspot.rename': [
    'superadmin',
    'manageHotspot',
    'renameHotspot',
    'editStatusHotspot',
  ],
  'hotspot.console': ['superadmin', 'interactHotspotConsole'], // ?
  'hotspot.editStatus': [
    'superadmin',
    'manageHotspot',
    'editStatusHotspot',
    'renameHotspot',
  ],
  'hotspot.ssh': ['superadmin', 'sshHotspot'],

  'megate.view': ['superadmin', 'viewMeGate', 'manageMeGate'],
  'megate.create': ['superadmin', 'manageMeGate'],
  'megate.edit': ['superadmin', 'manageMeGate'],
  'megate.delete': ['superadmin', 'manageMeGate'],
  'megate.config': ['superadmin', 'manageMeGateConfig'],
  'megate.viewConfig': ['superadmin', 'viewMeGateConfig', 'manageMeGateConfig'],
  'megate.console': ['superadmin', 'interactMeGateConsole'],
  'megate.vpn': ['superadmin'],

  'loginpage.view': ['superadmin', 'viewLoginPage', 'manageLoginPage'],
  'loginpage.create': ['superadmin', 'manageLoginPage'],
  'loginpage.edit': ['superadmin', 'manageLoginPage'],
  'loginpage.delete': ['superadmin', 'manageLoginPage'],
  'loginpage.rename': ['superadmin', 'manageLoginPage', 'renameLoginPage'],

  'code.view': ['superadmin', 'viewCodeUpdate', 'manageCodeUpdate'],
  'code.update': ['superadmin', 'manageCodeUpdate'],
  'code.delete': ['superadmin', 'manageCodeUpdate'],

  'monitor.view': ['superadmin', 'sysAdmin', 'viewMonitor'],

  'report.view': [
    'superadmin',
    'viewReportLocationInfo',
    'viewReportOnlineSession',
    'viewReportAPList',
    'viewReportMegateList',
    'viewReportSession',
    'viewReportSessionStat',
    'viewReportSessionAvg',
  ],
  'report.info': ['superadmin', 'viewReportLocationInfo'],
  'report.onlineSession': ['superadmin', 'viewReportOnlineSession'],
  'report.apList': ['superadmin', 'viewReportAPList'],
  'report.megateList': ['superadmin', 'viewReportMegateList'],
  'report.session': ['superadmin', 'viewReportSession'],
  'report.sessionStat': ['superadmin', 'viewReportSessionStat'],
  'report.sessionAvg': ['superadmin', 'viewReportSessionAvg'],
  'report.exportExcel': ['superadmin', 'sysadmin', 'exportReport'],
  'report.viewTab': ['superadmin', 'sysadmin', 'viewReportAsTabs'],

  'report.viewBillingReport': [
    'superadmin',
    'viewReportIncome',
    'viewReportIncomeByUser',
    'viewReportPack',
    'viewReportPackByUser',
  ],
  'report.income': ['superadmin', 'viewReportIncome'],
  'report.incomeByUser': [
    'superadmin',
    'viewReportIncomeByUser',
    'viewReportIncome',
  ],
  'report.pack': ['superadmin', 'viewReportPack'],
  'report.packByUser': ['superadmin', 'viewReportPackByUser'],
  'report.active': ['superadmin', 'viewReportActive'],
  'report.incomeAdj': ['superadmin', 'viewReportIncomeAdj'],

  'log.view': ['superadmin', 'sysadmin', 'viewAuditLog'],
  'log.viewAccessLogs': ['superadmin', 'sysadmin', 'viewAuditLog'],
  'log.viewConfigLogs': ['superadmin', 'sysadmin', 'viewAuditLog'],
  'log.viewBackupRestoreLog': ['superadmin', 'sysadmin', 'viewAuditLog'],
  'log.copyId': ['superadmin', 'sysadmin', 'viewAuditLog'],

  'settings.view': ['superadmin', 'sysadmin'],
};
