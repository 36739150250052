import 'promise-polyfill';
import 'jquery';

// import 'angular-ui-bootstrap';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/modal';
import 'bootstrap/js/collapse';
import 'bootstrap/js/tab';
import 'bootstrap/js/carousel';
import 'bootstrap/js/transition';

import angular from 'angular';
import ngTouch from 'angular-touch';
import ngSanitize from 'angular-sanitize';
import uiRouter from '@uirouter/angularjs';
import 'angular-resource';
import 'oclazyload';
import 'alertify.js/dist/js/ngAlertify';
import './extensions/modules/extend';
import '../assets/assets';

// ASSETS

const config = {};

export default angular
  .module('app', [
    ngTouch,
    ngSanitize,
    uiRouter,
    'oc.lazyLoad',
    'ngAlertify',
    // 'ui.bootstrap',
    // global
    require('./extensions/factories/config')(config).name,
    require('./extensions/extensions').name,
    require('./app.routing').name,
    require('./components/application/application.controller').name,
    require('../lib/lb-services'),
  ])
  .config(require('./extensions/init/lb-service'))
  .run(require('./components/layout'))
  .run(require('./extensions/init/access-control'))
  .run(require('./extensions/init/alertify'));

angular.element(document).ready(() => {
  const initInjector = angular.injector(['ng']);
  const $http = initInjector.get('$http');
  $http.get('/config.json').then(({ data }) => {
    Object.assign(config, data);
    angular.bootstrap(document, ['app']);
  });
});
