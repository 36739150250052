import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('user-group', {
    url: '/user-groups',
    templateProvider: ($templateCache) =>
      Promise.all([
        import(/* webpackChunkName: "user-group" */ './user-group.pug'),
        import(/* webpackChunkName: "user-group-fm" */ './user-group-fm.pug'),
      ]).then(([tpl, fmTpl]) => {
        $templateCache.put('user-group/user-group-fm', fmTpl.default);
        return tpl.default;
      }),
    controller: 'UserGroupCtrl as vm',
    requiredPermission: 'userGroup.view',
    resolve: {
      loadCtrl: ($ocLazyLoad) =>
        Promise.all([
          import(
            /* webpackChunkName: "user-group" */ './user-group.controller'
          ),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      locations: (Location) =>
        Location.find({
          filter: {
            fields: {
              id: 1,
              name: 1,
              status: 1,
              address: 1,
              group: 1,
              city: 1,
              district: 1,
              description: 1,
            },
          },
        }).$promise,
      users: (User) =>
        User.find({
          filter: {
            fields: {
              id: 1,
              name: 1,
              email: 1,
              username: 1,
            },
          },
        }).$promise,
      userAssigned: (UserGroup) =>
        UserGroup.find({
          filter: {
            fields: {
              id: 1,
              users: 1,
            },
          },
        }).$promise,
      templateConfigs: (ConfigTemplate) =>
        ConfigTemplate.find({
          filter: {
            fields: {
              id: 1,
              name: 1,
              description: 1,
            },
          },
        }).$promise,
      locationGroups: (CommonOption) =>
        CommonOption.find({
          filter: {
            where: { type: 'locationGroup' },
            fields: { key: 1, value: 1, meta: 1 },
          },
        }).$promise,
      cities: (CommonOption) =>
        CommonOption.find({
          filter: {
            where: { type: 'state' },
            fields: { key: 1, value: 1, meta: 2 },
          },
        }).$promise,
      translationText: ($translate) =>
        $translate([
          'USER_GROUP.USER_GROUP_TABLE.NO',
          'USER_GROUP.USER_GROUP_TABLE.NAME',
          'USER_GROUP.USER_GROUP_TABLE.DESCRIPTION',
          'USER_GROUP.USER_GROUP_TABLE.ACTIONS',
          'COMMON.REFRESH',
          'REPORT.DATETIME.ALL_LOC',
          'REPORT.DATETIME.LOC_GROUP',
          'USER.USER_TABLE.NO',
          'USER.USER_TABLE.NAME',
          'USER.USER_TABLE.USERNAME',
          'USER.USER_TABLE.EMAIL',
          'LOCATION.LOC_FM.CITY',
          'LOCATION.LOC_FM.DISTRICT',
          'TEMPLATE.TEMP_TABLE.NO',
          'TEMPLATE.TEMP_TABLE.NAME',
          'TEMPLATE.TEMP_TABLE.DESCRIPTION',
        ]),
    },
  });
}

export default angular.module('app.user-group.routing', []).config(routes);
