/* eslint class-methods-use-this: 1 */
/* eslint-env es6 */

import angular from 'angular';

import fmChangePasswordTemplate from './change-password.pug';

class ApplicationCtrl {
  constructor(
    $sce,
    $timeout,
    $state,
    $transitions,
    $layout,
    $templateCache,
    $translate,
    AuthEvents,
    AuthService,
    alertify,
    Messages,
    Config,
    User,
    Owner,
    RealtimeEvent,
    EventError,
  ) {
    'ngInject';

    this.$sce = $sce;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$transitions = $transitions;
    this.$layout = $layout;
    this.$templateCache = $templateCache;

    this.$translate = $translate;
    this.AuthEvents = AuthEvents;
    this.AuthService = AuthService;
    this.alertify = alertify;
    this.Messages = Messages;
    this.Config = Config;

    this.User = User;
    this.RealtimeEvent = RealtimeEvent;
    this.EventError = EventError;
    this.Owner = Owner;

    this.init();
    this.loaded = true;
  }

  init() {
    // gmap api
    this.googleMapApi = this.$sce.trustAsResourceUrl(
      [
        'https://maps.googleapis.com/maps/api/js?key=',
        this.Config.get('googleMapAPIKey'),
        '&libraries=places',
      ].join(''),
    );

    this.features = this.Config.get('features');

    this.$templateCache.put('change-password', fmChangePasswordTemplate);

    // update current user info
    this.AuthEvents.on(this.AuthEvents.currentUserUpdated, (currentUser) => {
      this.currentUser = currentUser;
      if (!currentUser) {
        this.menu = [];
      } else {
        this.menu = currentUser.menu;
      }
      this.menuLoaded = true;
    });

    // sidebar
    this.sidebarMini = localStorage.getItem('sidebar-xs');

    this.collapseSidebar = () => {
      const sidebarXs = $('body').hasClass('sidebar-xs');
      if (sidebarXs) {
        localStorage.removeItem('sidebar-xs');
        $('body').removeClass('sidebar-xs');
        $('.session-logo-md').css('display', 'block');
      } else {
        localStorage.setItem('sidebar-xs', true);
        $('body').addClass('sidebar-xs');
        $('.session-logo-md').css('display', 'none');
      }
    };

    this.sidebarMobile = () => {
      this.$timeout(() => {
        const menuMobile = $('body').hasClass('sidebar-mobile-main');
        if (menuMobile) {
          $('body').removeClass('sidebar-mobile-main');
        } else {
          $('body').addClass('sidebar-mobile-main');
        }
      });
    };

    $(document).on('mouseenter', '.sidebar-category', () => {
      const sidebarXs = $('body').hasClass('sidebar-xs');
      if (sidebarXs) {
        $('body').removeClass('sidebar-xs');
        $('body').addClass('sidebar-fixed-expanded');
      }
    });

    $(document).on('mouseleave', '.sidebar-category', () => {
      const sidebarExpanded = $('body').hasClass('sidebar-fixed-expanded');
      if (sidebarExpanded) {
        $('body').addClass('sidebar-xs');
        $('body').removeClass('sidebar-fixed-expanded');
      }
    });
  }

  setCurrentUser(currentUser) {
    this.currentUser = currentUser;
  }

  logout() {
    this.AuthService.logout();
  }

  isAuthenticated() {
    return this.AuthService.isAuthenticated();
  }

  changeLang() {
    const currLang =
      this.$translate.proposedLanguage() || this.$translate.use();
    switch (currLang) {
      case 'en':
        this.$translate.use('vi');
        break;
      case 'vi':
        this.$translate.use('en');
        break;
      default:
        break;
    }
    location.reload();
  }
}

export default angular
  .module('app.application.controller', [])
  .controller('ApplicationCtrl', ApplicationCtrl);
