import angular from 'angular';

const permissions = require('../../../lib/permission-mapping');

class AuthService {
  constructor($timeout, AuthEvents, LoopBackAuth, User) {
    'ngInject';

    this.$timeout = $timeout;
    this.AuthEvents = AuthEvents;
    this.LoopBackAuth = LoopBackAuth;
    this.User = User;

    this._roles = [];

    this.AuthEvents.on(this.AuthEvents.currentUserUpdated, (currentUser) => {
      this._user = currentUser;
      if (currentUser) {
        this._roles = currentUser.roles;
      } else {
        this._roles = [];
      }
    });
  }

  login(credentials, rememberMe = false) {
    return this.User.login({ rememberMe, include: 'user' }, credentials)
      .$promise.then((session) => {
        if (session.user) {
          this.AuthEvents.emit(this.AuthEvents.loginSuccess, session);
          this.AuthEvents.emit(this.AuthEvents.currentUserUpdated, session.user);
          return session.user;
        }
        return session;
      })
      .catch((err) => {
        this.AuthEvents.emit(this.AuthEvents.loginFailed, err);
        throw err;
      });
  }

  logout() {
    return this.User.logout().$promise.then(() => {
      this.AuthEvents.emit(this.AuthEvents.logoutSuccess);
    });
  }

  isAuthenticated() {
    return this.User.isAuthenticated();
  }

  isAuthorized = (action) => {
    if (!action) return false;
    if (Array.isArray(action)) {
      for (let i = 0; i < action.length; i += 1) {
        if (this.isAuthorized(action[i])) {
          return true;
        }
      }
      return false;
    }
    if (typeof action === 'object') {
      const keys = Object.keys(action);
      for (let i = 0; i < keys.length; i += 1) {
        if (!this.isAuthorized(keys[i])) {
          return false;
        }
      }
      return true;
    }
    if (typeof action !== 'string') return false;
    if (!(action in permissions)) return false;

    const permittedRoles = permissions[action];
    for (let i = 0; i < permittedRoles.length; i += 1) {
      if (this._roles.indexOf(permittedRoles[i]) > -1) {
        return true;
      }
    }
    return false;
  };

  getCurrentUser() {
    if (!this.isAuthenticated()) return Promise.resolve(false);

    const currentUser = this.User.getCachedCurrent();
    if (currentUser) return Promise.resolve(currentUser);

    return this.User.getCurrent().$promise.then((user) => {
      if (user) {
        this.AuthEvents.emit(this.AuthEvents.currentUserUpdated, user);
      }
      return user;
    });
  }

  setCurrentUser(user) {
    this.$timeout(() => {
      this.LoopBackAuth.currentUserData = user;
      this.AuthEvents.emit(this.AuthEvents.currentUserUpdated, user);
    });
  }
}

export default angular
  .module('app.factory.auth-service', [require('./auth-event').name])
  .factory('AuthService', AuthService);
