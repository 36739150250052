import angular from 'angular';
import moment from 'moment';

function routes($urlRouterProvider, $stateProvider) {
  'ngInject';

  $stateProvider
    .state('alertsNew', {
      url: '/alerts-new',
      requiredPermission: 'alerts.viewAlert',
      onEnter: ($state, $transition$) => {
        $transition$.abort();
        $state.go('alerts60mins', $transition$.params());
      },
    })
    .state('_alertsNew', {
      url: '/alerts-new',
      templateUrl: '/alerts-new',
      controller: 'AlertsNewCtrl as vm',
      requiredPermission: 'alerts.viewAlert',
      abstract: true,
      resolve: {
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "rAD" */ './alert-new.controller'),
            import(/* webpackChunkName: "rAD" */ './alert-new.pug'),
            // import('./filter/alerts-filter'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
          ]).then(([module, tpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put('/alerts-new', tpl.default);
          }),
        $emitter: () =>
          import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        locations: (Location) =>
          Location.find({
            filter: { fields: { id: 1, name: 1, group: 1, contactInfo: 1 } },
          }).$promise,
        hotspots: (Hotspot) =>
          Hotspot.find({
            filter: { fields: { id: 1, mac: 1, name: 1, locationId: 1 } },
          }).$promise,
        meGates: (MeGate) =>
          MeGate.find({
            filter: { fields: { id: 1, mac: 1, name: 1, locationId: 1 } },
          }).$promise,
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1 },
            },
          }).$promise,
        events: (RealtimeEvent) => RealtimeEvent.find().$promise,
        translationText: ($translate) =>
          $translate([
            'ALERTS.NO',
            'ALERTS.LOCATION',
            'ALERTS.OVERVIEW',
            'ALERTS.SEVERITY',
            'ALERTS.DEVICE',
            'ALERTS.CREATE_DAY',
            'ALERTS.END_DAY',
          ]),
      },
    })
    .state('alerts60mins', {
      parent: '_alertsNew',
      url: '/alert-60mins',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rAA" */ './alert-60mins/alert-60mins.pug'
        ).then(({ default: tpl }) => tpl),
      controller: 'Alert60minsCtrl as vm',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rAA" */ './alert-60mins/alert-60mins.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
      },
    })
    .state('alertsHistory', {
      parent: '_alertsNew',
      url: '/alert-history',
      onEnter: ($state, $transition$) => {
        $transition$.abort();
        $state.go('alertsUnfinished', $transition$.params());
      },
    })
    .state('_alertsHistory', {
      parent: '_alertsNew',
      url: '/alert-history',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rHA" */ './alert-history/alert-history-tabs.pug'
        ).then(({ default: tpl }) => tpl),
      controller: 'AlertsHistoryCtrl as vm',
      abstract: true,
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rAD" */ './alert-history/alert-history.controller'
            ),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        unfinishedEvents: (RealtimeEvent) =>
          RealtimeEvent.findEventsMoreThanOneHour({}).$promise,
        historyEvents: (HistoryEvent) =>
          HistoryEvent.find({
            filter: {
              limit: 10000,
              where: {
                createDay: {
                  gte: moment()
                    .subtract(1, 'weeks')
                    .startOf('day'),
                  lte: moment().endOf('day'),
                },
              },
            },
          }).$promise,
      },
    })
    .state('alertsUnfinished', {
      parent: '_alertsHistory',
      url: '/unfinished',
      params: {
        subcate: null,
      },
      templateProvider: () =>
        import(
          /* webpackChunkName: "rAA" */ './alert-history/alert-history-unfinished.pug'
        ).then(({ default: tpl }) => tpl),
      requiredPermission: 'alerts.viewAlert',

      controller: 'AlertsHistoryUnfinishedCtrl as vm',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rAA" */ './alert-history/alert-history-unfinished.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        unfinishedEvents: (RealtimeEvent) =>
          RealtimeEvent.findEventsMoreThanOneHour({}).$promise,
      },
    })
    .state('alertsFinished', {
      parent: '_alertsHistory',
      url: '/finished',
      templateProvider: () =>
        import(
          /* webpackChunkName: "rAM" */ './alert-history/alert-history-finished.pug'
        ).then(({ default: tpl }) => tpl),
      requiredPermission: 'alerts.viewFinishedEvents',
      controller: 'AlertsHistoryFinishedCtrl as vm',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "rAA" */ './alert-history/alert-history-finished.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        historyEvents: (HistoryEvent) =>
          HistoryEvent.find({
            filter: {
              limit: 10000,
              where: {
                and: [
                  {
                    createDay: {
                      gte: moment()
                        .subtract(1, 'weeks')
                        .startOf('day'),
                      lte: moment().endOf('day'),
                    },
                  },
                ],
              },
            },
          }).$promise,
      },
    });
}

export default angular.module('app.alert-new.routing', []).config(routes);
