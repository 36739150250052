/* eslint no-extend-native: 0 */

String.prototype.format = function format(...args) {
  return this.replace(
    /{(\d+)}/g,
    (match, number) => {
      if (typeof args[number] !== 'undefined') {
        return args[number];
      }
      return match;
    },
  );
};

Number.prototype.format = function format(c, d, t) {
  c = Math.abs(c);
  c = isNaN(c) ? 2 : c;
  d = d === undefined ? '.' : d;
  t = t === undefined ? ',' : t;

  const s = this < 0 ? '-' : '';
  const n = Math.abs(Number(this) || 0).toFixed(c);
  const i = parseInt(n, 10).toString();
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c ? d + Math.abs(this - i).toFixed(c).slice(2) : '')
  );
};

String.prototype.capitalize = function capitalize() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
