import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('account', {
      url: '/accounts',
      templateProvider: $templateCache =>
        Promise.all([
          import(/* webpackChunkName: "acc" */ './account-fm.pug'),
          import(/* webpackChunkName: "acc" */ '../base/fm-changepass.pug'),
          import(/* webpackChunkName: "acc" */ './account.pug'),
        ]).then(([fmTpl, changePassTpl, tpl]) => {
          $templateCache.put('account/account-fm', fmTpl.default);
          $templateCache.put('account/account-fm-pass', changePassTpl.default);
          return tpl.default;
        }),
      controller: 'AccountCtrl as vm',
      requiredPermission: 'account.view',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "acc" */ './account.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        locations: Location =>
          Location.find({ filter: { fields: { id: 1, name: 1, block: 1 } } })
            .$promise,
        plans: Plan =>
          Plan.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
        translationText: $translate =>
          $translate([
            'ACCOUNT.ACC_TABLE.NO',
            'ACCOUNT.ACC_TABLE.USERNAME',
            'ACCOUNT.ACC_TABLE.FULLNAME',
            'ACCOUNT.ACC_TABLE.EMAIL',
            'ACCOUNT.ACC_TABLE.PHONE',
            'ACCOUNT.ACC_TABLE.CREATED_AT',
            'ACCOUNT.ACC_TABLE.ACTIONS',
          ]),
      },
    })
    .state('accountDetail', {
      url: '/accounts/:id',
      params: {
        id: { type: 'string' },
      },
      abstract: true,
      templateUrl: 'accountDetail',
      resolve: {
        loadCtrl: $templateCache =>
          Promise.all([
            import(/* webpackChunkName: "aD" */ './account-detail.pug'),
          ]).then(([tpl]) => {
            $templateCache.put('accountDetail', tpl.default);
            return module.controller;
          }),
        account: (Account, $transition$) =>
          Account.findById({ id: $transition$.params().id }).$promise,
        translationText: $translate =>
          $translate([
            'ACCOUNT.ACCOUNT_INFO.ACC_INFO.SELECT_LOCATION',
            'ACCOUNT.ACCOUNT_INFO.DEV_LIMIT.SELECT_DEVICE',
          ]),
      },
    })
    .state('accountDetail.info', {
      url: '/info',
      controller: 'AccountDetailInfo as vm',
      templateUrl: 'accountDetail/info',
      requiredPermission: 'account.view',
      resolve: {
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "aD" */ './account-detail-info'),
            import(/* webpackChunkName: "aD" */ './account-detail-info.pug'),
            import(/* webpackChunkName: "aD" */ './account-detail-limit.pug'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker/index'),
          ]).then(([module, tpl, limitTpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put('accountDetail/info', tpl.default);
            $templateCache.put('accountDetail/limit', limitTpl.default);
          }),
        locations: Location =>
          Location.find({ filter: { fields: { id: 1, name: 1, block: 1 } } })
            .$promise,
      },
    })
    .state('accountDetail.pack', {
      url: '/packs?status',
      params: {
        status: { type: 'string', dynamic: true },
      },
      controller: 'AccountDetailPack as vm',
      templateUrl: 'accountDetail/pack',
      requiredPermission: 'account.view',
      resolve: {
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "aDP" */ './account-detail-pack'),
            import(/* webpackChunkName: "aDP" */ './account-detail-pack.pug'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module, tpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put('accountDetail/pack', tpl.default);
          }),
        translationText: $translate =>
          $translate([
            'ACCOUNT.PACK_HISTORY.NO',
            'ACCOUNT.PACK_HISTORY.PLAN',
            'ACCOUNT.PACK_HISTORY.PRICE',
            'ACCOUNT.PACK_HISTORY.SOLD_AT',
            'ACCOUNT.PACK_HISTORY.USED_AT',
          ]),
      },
    })
    .state('accountDetail.session', {
      url: '/sessions',
      controller: 'AccountDetailSession as vm',
      templateUrl: 'accountDetail/session',
      requiredPermission: 'account.view',
      resolve: {
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "aDP" */ './account-detail-session'),
            import(/* webpackChunkName: "aDP" */ './account-detail-session.pug'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module, tpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put('accountDetail/session', tpl.default);
          }),
        translationText: $translate =>
          $translate([
            'ACCOUNT.SESSION.NO',
            'ACCOUNT.SESSION.DEVICE',
            'ACCOUNT.SESSION.IP_ADDR',
            'ACCOUNT.SESSION.TIME',
            'ACCOUNT.SESSION.DATA',
            'ACCOUNT.SESSION.START_AT',
            'ACCOUNT.SESSION.END_AT',
          ]),
      },
    })
    .state('accountDetail.order', {
      url: '/order',
      controller: 'AccountDetailOrder as vm',
      templateUrl: 'accountDetail/order',
      requiredPermission: 'account.view',
      resolve: {
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "aDP" */ './account-detail-order'),
            import(/* webpackChunkName: "aDP" */ './account-detail-order.pug'),
            import('../../extensions/init/ag-grid'),
          ]).then(([module, tpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put('accountDetail/order', tpl.default);
          }),
        translationText: $translate =>
          $translate([
            'ACCOUNT.ORDER.NO',
            'ACCOUNT.ORDER.ORDER_CODE',
            'ACCOUNT.ORDER.BUY_DATE',
            'ACCOUNT.ORDER.TOTAL',
            'ACCOUNT.ORDER.STATUS',
            'ACCOUNT.ORDER.ORDER_STATUS.WAITING',
            'ACCOUNT.ORDER.ORDER_STATUS.CREATED',
            'ACCOUNT.ORDER.ORDER_STATUS.CANCELED',
            'ACCOUNT.ORDER.ORDER_STATUS.PAID',
            'ACCOUNT.ORDER.ORDER_STATUS.FAILED',
            'ACCOUNT.ORDER.ORDER_STATUS.FINISHED',
            'ACCOUNT.ORDER.ORDER_STATUS.UNKNOWN',
          ]),
      },
    });
}

export default angular.module('app.account.routing', []).config(routes);
