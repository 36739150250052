import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('_incomeAdjustment', {
      url: '/income-adjustment',
      abstract: true,
      templateUrl: '/income-adjustment',
      requiredPermission: 'billing.adjustIncome',
      resolve: {
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "iAu" */ './income-adjustment.controller'),
            import(/* webpackChunkName: "iAu" */ './income-adjustment.pug'),
            import(/* webpackChunkName: "iAu" */ './income-adjustment-detail.pug'),
            import(/* webpackChunkName: "iAu" */ './income-adjustment-fm.pug'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module, tpl, detailTpl, fmTpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put(
              '/income-adjustment',
              tpl.default,
            );
            $templateCache.put(
              '/income-adjustment-detail',
              detailTpl.default,
            );
            $templateCache.put(
              '/income-adjustment-fm',
              fmTpl.default,
            );
          }),
        plans: Plan =>
          Plan.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
        locations: Location =>
          Location.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
        users: User =>
          User.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
        translationText: $translate =>
          $translate([
            'INCOME_ADJ.INC_TABLE.NO',
            'INCOME_ADJ.INC_TABLE.LOCATION',
            'INCOME_ADJ.INC_TABLE.PLAN',
            'INCOME_ADJ.INC_TABLE.DECREASE',
            'INCOME_ADJ.INC_TABLE.ACTIONS',
          ]),
      },
    })
    .state('incomeAdjustment', {
      parent: '_incomeAdjustment',
      url: '',
      controller: 'IncomeAdjustmentCtrl as vm',
      templateUrl: '/income-adjustment-detail',
      requiredPermission: 'billing.adjustIncome',
      resolve: {
        filterConfig: () => ({ type: 'user' }),
      },
    })
    .state('incomeAdjustmentPlan', {
      parent: '_incomeAdjustment',
      url: '/plan',
      controller: 'IncomeAdjustmentCtrl as vm',
      templateUrl: '/income-adjustment-detail',
      requiredPermission: 'billing.adjustIncome',
      resolve: {
        filterConfig: () => ({ type: 'plan' }),
      },
    });
}
export default angular.module('app.income-adj.routing', []).config(routes);
