import angular from 'angular';

export default angular
  .module('app.directive.ngReplace', [])
  .directive('includeReplace', () => ({
    require: 'ngInclude',
    link(scope, el) {
      el.replaceWith(el.children());
    },
  }));
