import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('alertRealtime', {
    url: '/alert-realtime',
    templateProvider: () =>
      import(/* webpackChunkName: "alr" */ './alert-realtime.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'AlertRealtimeCtrl as vm',
    requiredPermission: 'alerts.viewAlert',
    resolve: {
      loadCtrl: ($ocLazyLoad) =>
        Promise.all([
          import(/* webpackChunkName: "alr" */ './alert-realtime.controller'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/magic-grid'),
          import('../../extensions/directives/sglclick'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      translationText: ($translate) =>
        $translate(['REPORT.DATETIME.ALL_LOC', 'REPORT.DATETIME.LOC_GROUP']),
      locationGroups: (CommonOption) =>
        CommonOption.find({
          filter: {
            where: { type: 'locationGroup' },
            fields: { key: 1, value: 1, meta: 1 },
          },
        }).$promise,
      locations: (Location) =>
        Location.find({
          filter: {
            fields: {
              id: 1,
              name: 1,
              group: 1,
              contactInfo: 1,
              status: 1,
            },
          },
        }).$promise,
      meGates: (Config, MeGate) => {
        if (!Config.get('features.manageMeGate.enabled')) {
          return [];
        }
        return MeGate.find({
          filter: {
            fields: {
              id: 1,
              name: 1,
            },
          },
        }).$promise;
      },
      hotspots: (Hotspot) =>
        Hotspot.find({
          filter: {
            fields: {
              id: 1,
              name: 1,
              mac: 1,
            },
          },
        }).$promise,
      users: (User) =>
        User.find({
          id: 1,
          name: 1,
        }),
      currentUser: (AuthService) => AuthService.getCurrentUser(),
    },
  });
}

export default angular.module('app.alertRealtime.routing', []).config(routes);
