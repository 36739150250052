import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('user', {
    url: '/users',
    templateProvider: $templateCache =>
      Promise.all([
        import(/* webpackChunkName: "user" */ './user-fm.pug'),
        import(/* webpackChunkName: "customer" */ '../base/fm-changepass.pug'),
        import(/* webpackChunkName: "user" */ './user.pug'),
      ]).then(([fmTpl, changePassTpl, tpl]) => {
        $templateCache.put('user/user-fm', fmTpl.default);
        $templateCache.put('user/user-fm-pass', changePassTpl.default);
        return tpl.default;
      }),
    controller: 'UserCtrl as vm',
    requiredPermission: 'user.view',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "user" */ './user.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      profiles: Profile => Profile.find().$promise,
      users: User => User.find().$promise,
      translationText: $translate =>
        $translate([
          'USER.USER_TABLE.NO',
          'USER.USER_TABLE.NAME',
          'USER.USER_TABLE.USERNAME',
          'USER.USER_TABLE.EMAIL',
          'USER.USER_TABLE.PROFILE',
          'USER.USER_TABLE.ACTIONS',
          'PROFILE.SELECT'
        ]),
    },
  });
}

export default angular.module('app.user.routing', []).config(routes);
