import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('_planOnline', {
      url: '/plans/online',
      abstract: true,
      resolve: {
        plans: Plan =>
          Plan.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
        locations: Location =>
          Location.find({ filter: { fields: { id: 1, name: 1 } } }).$promise,
      },
    })
    .state('planOnline', {
      parent: '_planOnline',
      url: '',
      controller: 'PlanOnlinesCtrl as vm',
      templateUrl: 'planOnlines',
      requiredPermission: 'planOnline.view',
      resolve: {
        loadCtrl: ($ocLazyLoad, $templateCache) =>
          Promise.all([
            import(/* webpackChunkName: "pOs" */ './plan-onlines'),
            import(/* webpackChunkName: "pOs" */ './plan-onlines.pug'),
            import(/* webpackChunkName: "pOs" */ './plan-onlines-fm.pug'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module, tpl, fmTpl]) => {
            $ocLazyLoad.load({ name: module.name });
            $templateCache.put('planOnlines', tpl.default);
            $templateCache.put('planOnlines/fm', fmTpl.default);
          }),
        translationText: $translate =>
          $translate([
            'PLAN_ONLINE.PLAN_TABLE.NO',
            'PLAN_ONLINE.PLAN_TABLE.LOCATION',
            'PLAN_ONLINE.PLAN_TABLE.PLAN',
            'PLAN_ONLINE.PLAN_TABLE.ACTION',
          ]),
      },
    })
    .state('planOnlineConfig', {
      parent: '_planOnline',
      url: '/:locationId/config',
      params: {
        locationId: { type: 'string' },
      },
      templateProvider: $templateCache =>
        Promise.all([
          import(/* webpackChunkName: "pO" */ './plan-online-fm-create.pug'),
          import(/* webpackChunkName: "pO" */ './plan-online.pug'),
        ]).then(([fmCreateTemplate, template]) => {
          $templateCache.put(
            'plan/plan-online-fm-create',
            fmCreateTemplate.default,
          );
          return template.default;
        }),
      controller: 'PlanOnlineCtrl as vm',
      requiredPermission: 'planOnline.view',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "pO" */ './plan-online.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        location: ($transition$, locations) => {
          const locationId = $transition$.params().locationId;
          return locations.find(item => item.id === locationId);
        },
        translationText: $translate =>
          $translate([
            'PLAN_ONLINE.PLAN_CONF_TABLE.NO',
            'PLAN_ONLINE.PLAN_CONF_TABLE.ORIG_PLAN',
            'PLAN_ONLINE.PLAN_CONF_TABLE.CUSTOM_NAME',
            'PLAN_ONLINE.PLAN_CONF_TABLE.CUSTOM_CODE',
            'PLAN_ONLINE.PLAN_CONF_TABLE.ACTIONS',
          ]),
      },
    });
}

export default angular.module('app.planOnline.routing', []).config(routes);
