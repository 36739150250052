import mainNavTemplate from './main-nav.pug';
import sidebarNewTemplate from './sidebar-new.pug';
import navbarTopTemplate from './navbar-top.pug';
import footerTemplate from './footer.pug';
import loadingTemplate from './loading.pug';
import './notification-style.less';

export default function ($templateCache) {
  'ngInject';

  $templateCache.put('layout/main-nav', mainNavTemplate);
  $templateCache.put('layout/sidebar-new', sidebarNewTemplate);
  $templateCache.put('layout/navbar-top', navbarTopTemplate);
  $templateCache.put('layout/footer', footerTemplate);
  $templateCache.put('layout/loading', loadingTemplate);
}
