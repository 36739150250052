import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider
    .state('auditLogs', {
      url: '/log',
      requiredPermission: 'log.view',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        if (AuthService.isAuthorized('log.viewAccessLogs')) {
          $state.go('accessLogs');
        } else if (AuthService.isAuthorized('log.viewConfigLogs')) {
          $state.go('configLogs');
        }
      },
    })
    .state('_auditLogs', {
      url: '/logs?user&location&from&to',
      abstract: true,
      params: {
        user: { type: 'string', dynamic: true },
        location: { type: 'string', dynamic: true },
        from: { type: 'string', dynamic: true },
        to: { type: 'string', dynamic: true },
      },
      templateProvider: () =>
        import(/* webpackChunkName: "adL" */ './audit-log.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'AuditLogCtrl as vm',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "adL" */ './audit-log.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
            import('../../extensions/directives/countTo'),
            import('../../extensions/directives/sticky/sticky'),
          ]).then(([module]) => {
            $ocLazyLoad.load({
              name: module.name,
            });
            return module.controller;
          }),
        $emitter: () =>
          import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        auditLogs: (AuditLog) => AuditLog.find().$promise,
        users: (User) =>
          User.find({
            filter: {
              fields: { id: 1, name: 1 },
            },
          }).$promise,
        locations: (Location) =>
          Location.find({
            filter: {
              fields: {
                id: 1,
                name: 1,
                status: 1,
              },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'LOG.TABLE.ACTIONS',
            'LOG.TABLE.DESCRIPTION',
            'LOG.TABLE.DATE',
            'LOG.TABLE.UPDATED_DATE',
            'LOG.TABLE.TYPE',
            'LOG.DETAIL_TABLE.SETTING',
            'LOG.DETAIL_TABLE.CURRENT',
            'LOG.DETAIL_TABLE.NEW',
            'LOG.AT',
            'LOG.HAS_CHANGE',
            'LOG.USER',
            'LOG.HAS_LOGIN',
            'LOG.HAS_LOGOUT',
            'LOG.BACKUP',
            'LOG.RESTORED',
            'REPORT.DATETIME.TIME',
            'REPORT.DATETIME.BY_HOUR',
            'REPORT.DATETIME.BY_DAY',
            'REPORT.DATETIME.BY_WEEK',
            'REPORT.DATETIME.BY_MONTH',
          ]),
      },
    })
    .state('accessLogs', {
      parent: '_auditLogs',
      url: '/access',
      templateProvider: () =>
        import(
          /* webpackChunkName: "adLAccess" */ './access-log/access-log.pug'
        ).then((tpl) => tpl.default),
      controller: 'AccessLogCtrl as vm',
      requiredPermission: 'log.viewAccessLogs',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "adLAccess" */ './access-log/access-log.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('configLogs', {
      parent: '_auditLogs',
      url: '/config-changes',
      templateProvider: () =>
        import(
          /* webpackChunkName: "adLCfg" */ './config-log/config-log.pug'
        ).then((tpl) => tpl.default),
      controller: 'ConfigLogCtrl as vm',
      requiredPermission: 'log.viewConfigLogs',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "adLCfg" */ './config-log/config-log.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        configTemplates: (ConfigTemplate) =>
          ConfigTemplate.find({ filter: { fields: { id: 1, name: 1 } } })
            .$promise,
      },
    })
    .state('backupRestoreLogs', {
      parent: '_auditLogs',
      url: '/backup-restore',
      templateProvider: () =>
        import(
          /* webpackChunkName: "adLBk" */ './backup-restore-log/backup-restore-log.pug'
        ).then((tpl) => tpl.default),
      controller: 'BackupRestoreLogCtrl as vm',
      requiredPermission: 'log.viewBackupRestoreLog',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "adLBk" */ './backup-restore-log/backup-restore-log.controller'
            ),
            import('../../extensions/init/ag-grid'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        configBackups: (ConfigBackup) => ConfigBackup.find().$promise,
      },
    });
}

export default angular.module('app.audit-log.routing', []).config(routes);
