export default function (
  $state,
  $transitions,
  AuthEvents,
  AuthService,
  alertify,
  Messages,
) {
  'ngInject';

  $transitions.onStart({}, (trans) => {
    const next = trans._targetState._definition;
    return AuthService.getCurrentUser().then((authenticated) => {
      if (next.name === 'login' && !authenticated) {
        return;
      }
      if (next.name === 'login' && authenticated) {
        return trans.router.stateService.target('default');
      }

      if (!authenticated) {
        return trans.router.stateService.target('login');
      }

      if (!next.self.requiredPermission) return;
      const authorized = AuthService.isAuthorized(next.self.requiredPermission);
      if (!authorized) {
        alertify.error(Messages.notAuthorized);
        return Promise.reject('Not Authorized');
      }
    });
  });

  const login = () => {
    $state.go('login');
  };

  AuthEvents.on(AuthEvents.loginSuccess, () => {
    $state.go('default');
  });
  AuthEvents.on(AuthEvents.loginFailed, () => {});
  AuthEvents.on(AuthEvents.logoutSuccess, login);
  AuthEvents.on(AuthEvents.notAuthenticated, login);
  AuthEvents.on(AuthEvents.notAuthorized, () => {
    alertify.error(Messages.notAuthorized);
  });
  AuthEvents.on(AuthEvents.sessionTimeout, login);
}
