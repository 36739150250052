import angular from 'angular';

function resolveLocationId($transition$, $state, $emitter, locations) {
  'ngInject';

  const params = $transition$.params();
  if (params.location) return params.location;

  const [location] = locations;
  if (!location) return;

  $emitter.emit('locationChanged', location.id);
  return location.id;
}

function routes($urlRouterProvider, $stateProvider, ConfigProvider) {
  'ngInject';

  const $config = ConfigProvider.$get();

  $stateProvider
    .state('deviceConsole', {
      url: '/device/console',
      requiredPermission: 'device.console',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        if (AuthService.isAuthorized('hotspot.console')) {
          $state.go('deviceConsoleAP');
        } else if (AuthService.isAuthorized('megate.console')) {
          $state.go('deviceConsoleMeGate');
        }
      },
    })
    .state('_deviceConsole', {
      url: '/device/console/:type?location',
      abstract: true,
      params: {
        type: {
          type: 'string',
          value: '',
          squash: true,
        },
        location: {
          type: 'string',
          value: '',
          dynamic: true,
        },
      },
      templateProvider: () =>
        import(/* webpackChunkName: "dCsl" */ './device-console.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'DeviceConsoleCtrl as vm',
      requiredPermission: 'device.view',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "dCsl" */ './device-console.controller'
            ),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/sticky/sticky'),
          ]).then(([module]) => {
            $ocLazyLoad.load({
              name: module.name,
            });
            return module.controller;
          }),
        $emitter: () =>
          import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        locations: (Location) =>
          Location.find({
            filter: {
              fields: {
                id: 1,
                name: 1,
              },
            },
          }).$promise,
      },
    })
    .state('deviceConsoleAP', {
      parent: '_deviceConsole',
      url: '/ap',
      views: {
        filter: {
          templateProvider: () =>
            import(
              /* webpackChunkName: "dFltA" */ '../device-config/device-filter-ap.pug'
            ).then((tpl) => tpl.default),
          controller: 'HotspotConfigFilterCtrl as vm',
        },
        '': {
          templateProvider: () =>
            import(
              /* webpackChunkName: "dCnfAC" */ './hotspot/ap-console.pug'
            ).then((tpl) => tpl.default),
          controller: 'AccessPointConsoleCtrl as vm',
        },
      },
      requiredPermission: 'hotspot.console',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "dCnfAC" */ './hotspot/ap-console.controller'
            ),
            import(
              /* webpackChunkName: "dFltA" */ '../device-config/device-filter-ap'
            ),
          ]).then(([module, filter]) => {
            $ocLazyLoad.load({ name: module.name });
            $ocLazyLoad.load({ name: filter.name });
          }),
        locationId: resolveLocationId,
        translationText: ($translate) =>
          $translate([
            'DEVICE_CONFIG.CONSOLE_AP.NETWORK',
            'DEVICE_CONFIG.CONSOLE_AP.WIRELESS',
            'DEVICE_CONFIG.CONSOLE_AP.RESTART_SERVICE',
            'DEVICE_CONFIG.CONSOLE_AP.REBOOT',
            'DEVICE_CONFIG.CONSOLE_AP.GET_CONFIG',
            'DEVICE_CONFIG.CONSOLE_AP.UPDATE_CONFIG',
            'DEVICE_CONFIG.CONSOLE_AP.GET_IP_PUBLIC',
            'DEVICE_CONFIG.CONSOLE_AP.GET_VERSION',
            'DEVICE_CONFIG.CONSOLE_AP.UPDATE_CODE',
          ]),
      },
    });

  if ($config.get('features.manageMeGate.enabled')) {
    $stateProvider.state('deviceConsoleMeGate', {
      parent: '_deviceConsole',
      url: '/megate',
      templateProvider: () =>
        import(
          /* webpackChunkName: "dCslMe" */ './megate/megate-console.pug'
        ).then((tpl) => tpl.default),
      controller: 'MeGateConsoleCtrl as vm',
      requiredPermission: 'megate.console',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "dCslMe" */ './megate/megate-console.controller'
            ),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
          }),
        locationId: resolveLocationId,
        translationText: ($translate) => $translate([]),
      },
    });
  }
}

export default angular.module('app.device-console.routing', []).config(routes);
