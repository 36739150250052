import angular from 'angular';

function routes($urlRouterProvider, $stateProvider, ConfigProvider) {
  'ngInject';

  const $config = ConfigProvider.$get();

  $stateProvider
    .state('device', {
      url: '/device',
      requiredPermission: 'device.view',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        if (AuthService.isAuthorized('hotspot.view')) {
          $state.go('deviceAp');
        } else if (AuthService.isAuthorized('megate.view')) {
          $state.go('deviceMegate');
        } else if (AuthService.isAuthorized('loginpage.view')) {
          $state.go('deviceLoginPage');
        }
      },
    })
    .state('_device', {
      url: '/device/:type?location',
      abstract: true,
      params: {
        type: {
          type: 'string',
          value: '',
          squash: true,
        },
        location: {
          type: 'string',
          value: '',
          dynamic: true,
        },
      },
      templateProvider: () =>
        import(/* webpackChunkName: "device" */ './device.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'DeviceCtrl as vm',
      requiredPermission: 'device.view',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(/* webpackChunkName: "device" */ './device.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
          ]).then(([module]) => {
            $ocLazyLoad.load({
              name: module.name,
            });
            return module.controller;
          }),
        $emitter: () =>
          import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        locations: (Location) =>
          Location.find({
            filter: {
              fields: {
                id: 1,
                name: 1,
                status: 1,
                address: 1,
                group: 1,
                city: 1,
                district: 1,
                description: 1,
              },
            },
          }).$promise,
      },
    })
    .state('deviceAp', {
      parent: '_device',
      url: '/aps',
      templateProvider: () =>
        import(
          /* webpackChunkName: "deviceAP" */ './hotspot/hotspots.pug'
        ).then((tpl) => tpl.default),
      controller: 'HotspotListCtrl as vm',
      requiredPermission: 'hotspot.view',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "deviceAp" */ './hotspot/hotspots.controller'
            ),
            import('../../extensions/directives/mac-address'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        translationText: ($translate) =>
          $translate([
            'HOTSPOT.HOT_TABLE.NO',
            'HOTSPOT.HOT_TABLE.NAME',
            'HOTSPOT.HOT_TABLE.MAC',
            'HOTSPOT.HOT_TABLE.LOCATION',
            'HOTSPOT.HOT_TABLE.ACTIONS',
            'COMMON.STATUS',
            'COMMON.IMPORT',
            'DEVICE_CONFIG.STATUS.ON_SITE',
            'DEVICE_CONFIG.STATUS.ON_STOCK',
            'DEVICE_CONFIG.STATUS.WARPING',
            'DEVICE_CONFIG.STATUS.UNWARP',
          ]),
      },
    })
    .state('deviceLoginPage', {
      parent: '_device',
      url: '/login-page',
      templateProvider: () =>
        import(
          /* webpackChunkName: "deviceLP" */ './template/template.pug'
        ).then((tpl) => tpl.default),
      controller: 'TemplateCtrl as vm',
      requiredPermission: 'loginpage.view',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "deviceLP" */ './template/template.controller'
            ),
            import('../../extensions/directives/highlight'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        locationGroups: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'locationGroup' },
              fields: { key: 1, value: 1, meta: 1 },
            },
          }).$promise,
        cities: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'state' },
              fields: { key: 1, value: 1, meta: 2 },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'TEMPLATE.TEMP_TABLE.NO',
            'TEMPLATE.TEMP_TABLE.NAME',
            'TEMPLATE.TEMP_TABLE.DESCRIPTION',
            'TEMPLATE.TEMP_TABLE.LOCATION',
            'TEMPLATE.TEMP_TABLE.ACTIONS',
            'COMMON.REFRESH',
            'REPORT.DATETIME.ALL_LOC',
            'REPORT.DATETIME.LOC_GROUP',
            'REPORT.DATETIME.ALL_HOTSPOT',
            'REPORT.DATETIME.CHOOSE_STAFF',
            'REPORT.DATETIME.TIME',
            'REPORT.DATETIME.BY_HOUR',
            'REPORT.DATETIME.BY_DAY',
            'REPORT.DATETIME.BY_WEEK',
            'REPORT.DATETIME.BY_MONTH',
            'LOCATION.LOC_FM.CITY',
            'LOCATION.LOC_FM.DISTRICT',
          ]),
      },
    });

  if ($config.get('features.manageMeGate.enabled')) {
    $stateProvider.state('deviceMegate', {
      parent: '_device',
      url: '/megates',
      templateProvider: () =>
        import(/* webpackChunkName: "deviceM" */ './megate/megates.pug').then(
          (tpl) => tpl.default,
        ),
      controller: 'MeGateCtrl as vm',
      requiredPermission: 'megate.view',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "deviceM" */ './megate/megates.controller'
            ),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        megateModels: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'megatemodel' },
              fields: { value: 1, key: 1, meta: 1 },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'MEGATE.ME_TABLE.NO',
            'MEGATE.ME_TABLE.NAME',
            'MEGATE.ME_TABLE.SERIAL',
            'MEGATE.ME_TABLE.MAC',
            'MEGATE.ME_TABLE.MODEL',
            'MEGATE.ME_TABLE.LOCATION',
            'MEGATE.ME_TABLE.ACTIONS',
            'DEVICE_CONFIG.STATUS.ON_SITE',
            'DEVICE_CONFIG.STATUS.ON_STOCK',
            'DEVICE_CONFIG.STATUS.WARPING',
            'DEVICE_CONFIG.STATUS.UNWARP',
            'COMMON.STATUS',
          ]),
      },
    });
  }

  if ($config.get('features.manageOtherDevice.enabled')) {
    $stateProvider.state('otherDevice', {
      parent: '_device',
      url: '/other',
      templateProvider: () =>
        import(
          /* webpackChunkName: "deviceO" */ './other-device/other-device.pug'
        ).then((tpl) => tpl.default),
      controller: 'OtherCtrl as vm',
      requiredPermission: 'megate.view',
      resolve: {
        loadCtrl: ($ocLazyLoad) =>
          Promise.all([
            import(
              /* webpackChunkName: "deviceO" */ './other-device/other-device.controller'
            ),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        otherModels: (CommonOption) =>
          CommonOption.find({
            filter: {
              where: { type: 'othermodel' },
              fields: { value: 1, key: 1, meta: 1, type: 1 },
            },
          }).$promise,
        translationText: ($translate) =>
          $translate([
            'MEGATE.ME_TABLE.NO',
            'MEGATE.ME_TABLE.NAME',
            'MEGATE.ME_TABLE.SERIAL',
            'MEGATE.ME_TABLE.MAC',
            'MEGATE.ME_TABLE.MODEL',
            'MEGATE.ME_TABLE.LOCATION',
            'MEGATE.ME_TABLE.ACTIONS',
            'DEVICE_CONFIG.STATUS.ON_SITE',
            'DEVICE_CONFIG.STATUS.ON_STOCK',
            'DEVICE_CONFIG.STATUS.WARPING',
            'DEVICE_CONFIG.STATUS.UNWARP',
            'COMMON.STATUS',
            'COMMON.DESCRIPTION',
            'OTHER_DEVICE.TYPE',
          ]),
      },
    });
  }
}

export default angular.module('app.device.routing', []).config(routes);
