import angular from 'angular';

function AlertMessages($translate) {
  'ngInject';

  const alertMessages = {};
  const alertMessageMap = {
    'ALERT_MSG.NOT_AUTHORIZED': 'notAuthorized',
    'ALERT_MSG.CONFIRM_DEL': 'confirmDelete',
    'ALERT_MSG.ACTION_CONFIRM': 'actionConfirm',
    'ALERT_MSG.CANCEL': 'cancel',
    'ALERT_MSG.CREATE_SUCCESS': 'createSuccess',
    'ALERT_MSG.UPDATE_SUCCESS': 'updateSuccess',
    'ALERT_MSG.DEL_SUCCESS': 'deleteSuccess',
    'ALERT_MSG.REFRESH_SUCCESS': 'refreshSuccess',
    'ALERT_MSG.CHANGE_PASS_SUCCESS': 'changePasswordSuccess',
    'ALERT_MSG.ACTION_SUCCESS': 'actionSuccess',
    'ALERT_MSG.CREATE_FAILED': 'createFailed',
    'ALERT_MSG.UPDATE_FAILED': 'updateFailed',
    'ALERT_MSG.DELETE_FAILED': 'deleteFailed',
    'ALERT_MSG.REFRESH_FAILED': 'refreshFailed',
    'ALERT_MSG.CHANGE_PASS_FAILED': 'changePasswordFailed',
    'ALERT_MSG.ACTION_FAILED': 'actionFailed',
    'ALERT_MSG.COPY_SUCCESS': 'copyToClipboardSuccess',
    'ALERT_MSG.COPY_FAILED': 'copyToClipboardFailed',
    'ALERT_MSG.CHART_NO_DATA': 'chartNoData',
    'ALERT_MSG.PLAN_CHANGE_TYPE': 'PLAN_CHANGE_TYPE',
    'ALERT_MSG.LOCATION_NON_EXISTED': 'locationNotExisted',
    'ALERT_MSG.INVALID_2FA_TOKEN': 'invalid2FAToken',
    'ALERT_MSG.INVALID_PASSWORD': 'invalidPassword',
    'ALERT_MSG.EXISTS': 'exists',
  };

  $translate(Object.keys(alertMessageMap)).then((result) => {
    Object.keys(result).forEach((key) => {
      alertMessages[alertMessageMap[key]] = result[key];
    });
  });

  return alertMessages;
}

export default angular
  .module('app.factory.messages', [])
  .factory('Messages', AlertMessages);
