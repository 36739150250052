import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('customer', {
    url: '/customers',
    templateProvider: $templateCache =>
      Promise.all([
        import(/* webpackChunkName: "customer" */ './customer-fm.pug'),
        import(/* webpackChunkName: "customer" */ '../base/fm-changepass.pug'),
        import(/* webpackChunkName: "customer" */ './customer.pug'),
      ]).then(([fmTpl, changePassTpl, tpl]) => {
        $templateCache.put('customer/customer-fm', fmTpl.default);
        $templateCache.put('customer/customer-fm-pass', changePassTpl.default);
        return tpl.default;
      }),
    controller: 'CustomerCtrl as vm',
    requiredPermission: 'customer.view',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "customer" */ './customer.controller'),
          import('../../extensions/init/ag-grid'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      profiles: Profile => Profile.find().$promise,
      customers: Customer => Customer.find().$promise,
      translationText: $translate =>
        $translate([
          'CUSTOMER.CUS_TABLE.NO',
          'CUSTOMER.CUS_TABLE.NAME',
          'CUSTOMER.CUS_TABLE.USERNAME',
          'CUSTOMER.CUS_TABLE.EMAIL',
          'CUSTOMER.CUS_TABLE.PROFILE',
          'CUSTOMER.CUS_TABLE.PERMISSION',
          'CUSTOMER.CUS_TABLE.ACTIONS',
        ]),
    },
  });
}

export default angular.module('app.customer.routing', []).config(routes);
