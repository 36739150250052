import angular from 'angular';
import _get from 'lodash/get';

export default (config) => {
  const configGetter = {
    get: (key) => _get(config, key),
  };

  function ConfigProvider() {
    this.$get = () => configGetter;
  }

  return angular
    .module('app.factory.config', [])
    .provider('Config', ConfigProvider)
    .factory('Config', () => configGetter);
};
