import angular from 'angular';

class Copy {
  constructor($clipboard) {
    'ngInject';

    this.restrict = 'A';
    this.scope = {
      value: '=copy',
    };

    this.$clipboard = $clipboard;
  }

  link(scope, eles) {
    const [ele] = eles;
    ele.addEventListener('click', () => {
      this.$clipboard.copy(scope.value);
    });
  }
}

export default angular.module('app.directive.copy', []).directive('copy', Copy);
