import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('packRoom', {
    url: '/packs/selling/group',

    controller: 'PackRoomCtrl as vm',
    templateUrl: 'pack-group',
    requiredPermission: 'pack.create',
    resolve: {
      loadCtrl: ($ocLazyLoad, $templateCache) =>
        Promise.all([
          import(/* webpackChunkName: "packG" */ './pack-group.controller'),
          import(/* webpackChunkName: "packG" */ './pack-group.pug'),
          import(/* webpackChunkName: "packG" */ './pack-group-checkout-fm.pug'),
          import('../account/account-search-result'),
          import('../../extensions/directives/select2'),
        ]).then(([module, tpl, checkoutTpl]) => {
          $ocLazyLoad.load({ name: module.name });
          $templateCache.put('pack-group', tpl.default);
          $templateCache.put('pack-group/checkout-fm', checkoutTpl.default);
        }),
      plans: Plan =>
        Plan.find({
          filter: { where: { 'options.noUser': { exists: true } } },
        }).$promise,
      translationText: $translate =>
        $translate([
          'PACK_GROUP.SEARCH_PLAN',
          'PACK_GROUP.SEARCH_ACCOUNT',
          'PACK_GROUP.CHECKOUT',
          'PACK_GROUP.MAX_ACC_REACHED',
          'PACK_GROUP.ACC_ALREADY_SELECTED',
        ]),
    },
  });
}

export default angular.module('app.pack-room.routing', []).config(routes);
