import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('userInfo', {
    url: '/me',
    templateProvider: () =>
      Promise.all([
        import(/* webpackChunkName: "userInfo" */ './user-info.pug'),
      ]).then(([tpl]) => tpl.default),
    controller: 'UserInfoCtrl as vm',
    // requiredPermission: 'user.view',
    resolve: {
      loadCtrl: ($ocLazyLoad) =>
        Promise.all([
          import(/* webpackChunkName: "user" */ './user-info'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      translationText: ($translate) =>
        $translate([
          'USER.UPDATE_PERSONAL_INFO',
          'USER.2FA_FM.ENABLE',
          'USER.2FA_FM.DISABLE',
        ]),
    },
  });
}

export default angular.module('app.user-info.routing', []).config(routes);
