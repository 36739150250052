import angular from 'angular';

const template = require('./file.pug');

class InputFileCtrl {
  constructor($scope, $timeout) {
    'ngInject';

    this.$s = $scope;
    this.$timeout = $timeout;
  }

  fileSelect = () => {
    this.ele.click();
  };

  handleFileChange = (evt) => {
    const [file] = evt.target.files;
    if (typeof this.$s.onChange === 'function') {
      this.$s.onChange(file);
    }

    this.$timeout(() => {
      this.fileName = file ? file.name : '';
    });
  };
}

class InputFile {
  constructor() {
    this.restrict = 'E';
    this.template = template;

    this.controller = 'InputFileCtrl as vm';
    this.scope = {
      onChange: '=',
      accept: '@'
    };
  }

  link(scope, ele) {
    const file = ele.find('input[type="file"]');
    scope.vm.ele = file;
    scope.vm.onChange = scope.onChange;
    file.bind('change', scope.vm.handleFileChange);
  }
}

export default angular
  .module('app.directive.file', [])
  .controller('InputFileCtrl', InputFileCtrl)
  .directive('inputFile', InputFile);
