import angular from 'angular';

class CopyToClipBoard {
  constructor(alertify, Messages) {
    'ngInject';

    this.alertify = alertify;
    this.Messages = Messages;
  }

  _handleSuccess = () => {
    this.alertify.success(this.Messages.copyToClipboardSuccess);
  }

  _handleFailure = () => {
    this.alertify.error(this.Messages.copyToClipboardFailed);
  }

  copy = (value) => {
    const textarea = document.createElement('textarea');
    textarea.textContent = value;
    document.body.appendChild(textarea);
    textarea.select();

    return new Promise((resolve, reject) => {
      try {
        if (document.execCommand('copy')) {
          this._handleSuccess();
          resolve();
        } else {
          this._handleFailure();
          reject();
        }
      } catch (err) {
        this._handleFailure();
        reject(err);
      } finally {
        textarea.parentNode.removeChild(textarea);
      }
    });
  };
}

export default angular.module('app.factory.copy-to-clipboard', [])
  .factory('$clipboard', CopyToClipBoard);
