import angular from 'angular';
import _get from 'lodash/get';

class AuthInterceptor {
  constructor(AuthEvents, LoopBackAuth) {
    'ngInject';

    this.AuthEvents = AuthEvents;
    this.LoopBackAuth = LoopBackAuth;
  }

  responseError = (response) => {
    switch (response.status) {
      case 401:
        {
          const errorCode = _get(response, 'data.error.code');
          if (errorCode === 'AUTHORIZATION_REQUIRED') {
            this.AuthEvents.emit(this.AuthEvents.notAuthorized, response);
          } else {
            this.LoopBackAuth.clearUser();
            this.LoopBackAuth.clearStorage();
            this.AuthEvents.emit(this.AuthEvents.notAuthenticated, response);
          }
        }
        break;
      case 403:
        this.AuthEvents.emit(this.AuthEvents.notAuthorized, response);
        break;
      case 419:
      case 440:
        this.LoopBackAuth.clearUser();
        this.LoopBackAuth.clearStorage();
        this.AuthEvents.emit(this.AuthEvents.sessionTimeout, response);
        break;
      default:
        break;
    }
    return Promise.reject(response);
  };
}

export default angular
  .module('app.factory.auth-interceptor', [])
  .factory('AuthInterceptor', AuthInterceptor)
  .config([
    '$httpProvider',
    ($httpProvider) => {
      $httpProvider.interceptors.push('AuthInterceptor');
    },
  ]);
