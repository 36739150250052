import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('pack', {
    url: '/packs/selling?account=',
    params: {
      account: { type: 'string', dynamic: true },
    },
    controller: 'PackCtrl as vm',
    templateUrl: 'pack',
    // requiredPermission: 'pack.create',
    resolve: {
      loadCtrl: ($ocLazyLoad, $templateCache) =>
        Promise.all([
          import(/* webpackChunkName: "pack" */ './pack.controller'),
          import(/* webpackChunkName: "pack" */ './pack.pug'),
          import('../account/account-search-result'),
          import('../../extensions/directives/select2'),
        ]).then(([module, tpl]) => {
          $ocLazyLoad.load({ name: module.name });
          $templateCache.put('pack', tpl.default);
        }),
      plans: Plan =>
        Plan.find({
          filter: { where: { 'options.noUser': { exists: false } } },
        }).$promise,
      translationText: $translate => $translate(['PACK.SEARCH']),
    },
  });
}

export default angular.module('app.pack.routing', []).config(routes);
